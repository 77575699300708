const ListIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      fill='none'
      viewBox='0 0 30 30'
    >
      <g
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      >
        <path d='M16.6 6.993h-6.405c-.425 0-.832.169-1.132.469-.3.3-.47.708-.47 1.132v12.812c0 .424.17.832.47 1.132.3.3.707.469 1.132.469h9.609c.424 0 .832-.169 1.132-.469.3-.3.469-.708.469-1.132v-9.609l-4.804-4.804z' />
        <path d='M16.602 6.993v4.804h4.804' />
      </g>
    </svg>
  );
};

export default ListIcon;
