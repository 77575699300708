import styled from '@emotion/styled';
import IconButton from '@material-ui/core/IconButton';
import { useState } from 'react';
import React, { useContext } from 'react';
import { useEffect } from 'react';

import { Context, ContextActions, ToastType } from '@/client/context';
import SVG from '@/svgs';

const TOAST_MAX_COUNT = 5;

export const ToastComponent = ({
  data,
  onClose,
}: {
  data: ToastType;
  onClose: (id: number) => void;
}) => {
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    const countDown = setTimeout(() => {
      const newCount = count + 1;
      if (newCount === TOAST_MAX_COUNT + 1) {
        clearInterval(countDown);
        onClose(data.id);
      } else {
        setCount(newCount);
      }
    }, 1000);
    return () => {
      clearInterval(countDown);
    };
  }, [count, data.id, onClose]);

  return (
    <div>
      {data.message}

      <IconButton
        size='small'
        aria-label='close'
        color='inherit'
        onClick={() => onClose(data.id)}
      >
        <SVG.CloseIcon />
      </IconButton>
    </div>
  );
};

const Toast: React.FC = () => {
  const { contextState, contextDispatch } = useContext(Context);

  const onClose = (id: number) => {
    contextDispatch({ type: ContextActions.REMOVE_TOAST, value: id });
  };

  return (
    <ToastWrap>
      {contextState?.toast?.map((toast: ToastType) => {
        return <ToastComponent key={toast.id} data={toast} onClose={onClose} />;
      })}
    </ToastWrap>
  );
};

export default Toast;

export const ToastWrap = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 9999;
  > div {
    background-color: #393b41;
    border-radius: 8px;
    color: #fff;
    flex-grow: 0;
    font-size: 14px;
    margin-bottom: 10px;
    min-height: 44px;
    padding: 12px 24px 12px 16px;
    position: relative;
    width: 312px;
    > button {
      position: absolute;
      right: 6px;
      top: 6px;
      svg {
        transform: scale(0.8);
        path[stroke='black'] {
          stroke: #fff;
        }
      }
    }
  }
`;
