import { gql } from '@apollo/client';

import { EnterpriseMemberStatus } from '@/client/queries/members';

export interface EnterpriseCompany {
  pk: number;
  permalink: string;
  name: string;
  logo: string;
}
export interface EnterpriseAccount {
  pk: number;
  permalink: string;
  company: EnterpriseCompany;
  ownerMail: string;
  paymentNoticeMailingList: [string];
  enableVisit: boolean;
  availablePass: number;
  totalPass: number;
  hasVisitedDashboard: boolean;
}
export interface EnterpriseMembership {
  account: EnterpriseAccount;
  department: string;
  email: string;
  emailSettings: EnterpriseEmailSettings;
  emailVerified: boolean;
  name: string;
  phone: string;
  phoneCountryCode: string;
  phoneVerified: boolean;
  pk: number;
  position: string;
  status: EnterpriseMemberStatus;
}
export interface EnterpriseEmailSettings {
  accountChanges: boolean;
  extraChargeAlert: boolean;
  memberChanges: boolean;
  newsletter: boolean;
}

export interface MeMemberType {
  pk: number;
  username: string;
  firstName: string;
  lastName: string;
  name: string;
  phone: string;
  phoneCountryCode: string;
  phoneVerified: boolean;
  email: string;
  emailVerified: Boolean;
  imageUrl: string;
  specialties: {
    specialty: {
      pk: number;
      name: string;
      logo?: string;
      permalink?: string;
    };
  }[];
  careers: {
    company: {
      pk: number;
      name: string;
    };
    jobName: string;
    isCurrent: boolean;
  }[];
  enterpriseMemberships: EnterpriseMembership[];
}

export interface MeQueryResult {
  me: MeMemberType;
}

export const meQuery = gql`
  query Me {
    me {
      username
      firstName
      lastName
      name
      phone
      phoneCountryCode
      phoneVerified
      email
      emailVerified
      imageUrl
      pk
      specialties {
        specialty {
          pk
          name
          logo
          permalink
        }
      }
      careers {
        company {
          pk
          name
          permalink
          logo
        }
        jobName
        isCurrent
      }
      enterpriseMemberships {
        account {
          pk
          permalink
          company {
            pk
            permalink
            name
            logo
          }
          availablePass
          totalPass
          ownerMail
          paymentNoticeMailingList
          enableVisit
          hasVisitedDashboard
        }
        emailSettings {
          accountChanges
          extraChargeAlert
          memberChanges
          newsletter
        }
        email
        emailVerified
        name
        phone
        phoneCountryCode
        phoneVerified
        department
        pk
        position
        status
      }
    }
  }
`;

export interface UserMutationVariables {
  id: number;
  user: {
    firstName?: string;
    lastName?: string;
    image?: File;
    specialtyNames?: string[];
  };
}

export const userMutation = gql`
  mutation user($id: Int!, $user: UserInput!) {
    user(id: $id, user: $user) {
      user {
        pk
      }
    }
  }
`;

export interface AddCareerMutationVariables {
  career: {
    companyId: number;
    companyName: string;
    jobTitleName: string;
    isCurrent: boolean;
  };
}

export const addCareerMutation = gql`
  mutation AddCareer($career: CareerInput!) {
    addCareer(career: $career) {
      career {
        pk
      }
    }
  }
`;
