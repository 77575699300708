import type { AppProps } from 'next/app';

import { ApolloProvider } from '@apollo/client';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { RecoilRoot } from 'recoil';

import { client } from '../client';

import { Provider } from '@/client/context';
import { tagManager } from '@/client/gta';
import Toast from '@/components/toast/Toast';
import GlobalLayout from '@/layout/GlobalLayout';
import 'react-datepicker/dist/react-datepicker.css';

const manifest = {
  name: '집무실 엔터프라이즈',
  short_name: '집무실 엔터프라이즈',
  theme_color: '#232935',
  background_color: '#232935',
  display: 'fullscreen',
  orientation: 'landscape',
  icons: [
    {
      src: '/icons/icon-72x72.png',
      sizes: '72x72',
      type: 'image/png',
    },
    {
      src: '/icons/icon-96x96.png',
      sizes: '96x96',
      type: 'image/png',
    },
    {
      src: '/icons/icon-144x144.png',
      sizes: '144x144',
      type: 'image/png',
    },
    {
      src: '/icons/icon-192x192.png',
      sizes: '192x192',
      type: 'image/png',
    },
    {
      src: '/icons/icon-384x384.png',
      sizes: '384x384',
      type: 'image/png',
    },
    {
      src: '/icons/icon-512x512.png',
      sizes: '512x512',
      type: 'image/png',
    },
  ],
  splash_pages: null,
};

const App = ({ Component, pageProps, ...rest }: AppProps) => {
  const router = useRouter();
  const currentUrl = router.asPath;

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      tagManager();
    }

    const manifestElement = document.getElementById('manifest');
    const manifestString = JSON.stringify({
      ...manifest,
      start_url: currentUrl,
    });
    manifestElement?.setAttribute(
      'href',
      'data:application/json;charset=utf-8,' +
        encodeURIComponent(manifestString),
    );
  }, [currentUrl]);
  const metaTags = pageProps.metaTags;

  return (
    <RecoilRoot>
      <ApolloProvider client={client}>
        <Provider>
          <Head>
            <meta charSet='UTF-8' />
            <title>집무실 엔터프라이즈 | No.1 분산 오피스, 집무실</title>
            <meta name='description' content='No.1 분산 오피스, 집무실' />
            <meta
              name='keyword'
              content='집무실, 집근처사무실, jibmusil, 분산근무, 분산오피스, 리모트워크, 원격근무, 재택근무, remote work. 거점오피스, 거점근무, 거점업무, 개인업무환경, 업무공간, 소호사무실, 서울시청사무실, 관악구사무실, 석촌사무실, 일산사무실, 사무실, 1인 사무실'
            />
            <meta property='og:type' content='website' />
            <meta
              property='og:title'
              content='집무실 엔터프라이즈 | No.1 분산 오피스, 집무실'
            />
            <meta
              property='og:url'
              content='https://enterprise.jibmusil.com/'
            />
            <meta
              property='og:description'
              content='No.1 분산 오피스, 집무실'
            />
            <meta property='og:site_name' content='집무실 엔터프라이즈' />
            <meta property='og:locale' content='ko_KR' />
            <meta
              name='keyword'
              content='집무실, 집근처사무실, jibmusil, 분산근무, 분산오피스, 리모트워크, 원격근무, 재택근무, remote work. 거점오피스, 거점근무, 거점업무, 개인업무환경, 업무공간, 소호사무실, 서울시청사무실, 관악구사무실, 석촌사무실, 일산사무실, 사무실, 1인 사무실'
            />
            <meta
              name='viewport'
              content='height=device-height, initial-scale=1'
            />
            <meta name='mobile-web-app-capable' content='yes' />
            <meta name='apple-mobile-web-app-capable' content='yes' />
            {metaTags &&
              metaTags.map(
                (attributes: { [key: string]: string }, index: number) => (
                  <meta {...attributes} key={index} />
                ),
              )}
          </Head>
          <Toast />

          <GlobalLayout {...pageProps} {...rest}>
            <Component key={router.asPath} {...pageProps} {...rest} />
          </GlobalLayout>
        </Provider>
      </ApolloProvider>
    </RecoilRoot>
  );
};

export default App;
