const LineChartLabelIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M0 10h20' stroke='black' strokeWidth='2' />
      <rect
        x='7.624'
        y='7.624'
        width='4.751'
        height='4.751'
        rx='2.376'
        fill='black'
        stroke='#fff'
        strokeWidth='.751'
      />
    </svg>
  );
};

export default LineChartLabelIcon;
