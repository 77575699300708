const MarkIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='80'
      height='16'
      fill='none'
      viewBox='0 0 16 16'
    >
      <path fill='black' d='M11.5 15l-3.635-3.304L4.5 15V3h7v12z' />
    </svg>
  );
};

export default MarkIcon;
