const PlusCircleFillIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      fill='none'
      viewBox='0 0 32 32'
    >
      <circle cx='16' cy='16' r='16' fill='black' />
      <path
        stroke='#fff'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M16 11v10M11 16h10'
      />
    </svg>
  );
};

export default PlusCircleFillIcon;
