import { gql } from '@apollo/client';

import { EnterpriseMemberStatus } from './members';
import { PlanStatusType } from './payments';

const companyField = gql`
  fragment companyField on Company {
    pk
    name
    logo
    memberCount
    enterpriseAccounts {
      hasVisitedDashboard
    }
  }
`;
const accessibleSitesField = gql`
  fragment accessibleSitesField on CheckEnterprisePermissionType {
    accessibleSites {
      name
      isJibmusil
      pk
      meetingRoomType
    }
  }
`;

export enum MeetingRoomEnumType {
  NOT_A_MEETING_ROOM = 'NOT_A_MEETING_ROOM',
  GENERAL_PRIVATE_MEETING_ROOM = 'GENERAL_PRIVATE_MEETING_ROOM',
  GENERAL_PUBLIC_MEETING_ROOM = 'GENERAL_PUBLIC_MEETING_ROOM',
}

export interface EnterpriseAccountType {
  availablePass: number;
  enableVisit: boolean;
  manageWorkstay: boolean;
  enableSelfRegister: boolean;
  emailVerifyRequiredWhenSelfRegister: boolean;
  id: number;
  ownerMail: string;
  paymentNoticeMailingList: string[];
  permalink: string;
  pk: number;
  totalPass: number;
  domains: string[];
}
export interface CompanyType {
  permalink: string;
  pk: number;
  name: string;
  logo: string;
  memberCount: number;
  enterpriseAccounts?: {
    hasVisitedDashboard: boolean;
  }[];
}
export interface AccessibleSitesType {
  name: string;
  isJibmusil: boolean;
  pk: number;
  meetingRoomType: MeetingRoomEnumType;
}

export interface CheckEnterprisePermissionMeEnterpriseType {
  account: {
    permalink: string;
    hasVisitedDashboard: boolean;
  };
  status: EnterpriseMemberStatus;
  emailVerified: boolean;
  enabled: boolean;
}

export interface CheckEnterprisePermissionMeType {
  pk: number;
  enterpriseMemberships: CheckEnterprisePermissionMeEnterpriseType[];
}

export interface CheckEnterprisePermissionResult {
  checkEnterprisePermission: {
    account: EnterpriseAccountType;
    company: CompanyType;
    me: CheckEnterprisePermissionMeType;
    subscription: {
      status: PlanStatusType;
    };
    groupPass: {
      totalPass: number;
      remainPass: number;
    };
    totalCount: number;
    accessibleSites: AccessibleSitesType[];
  };
}

export const checkEnterprisePermissionQuery = gql`
  query CheckEnterprisePermission($permalink: String!) {
    checkEnterprisePermission(permalink: $permalink) {
      account {
        availablePass
        enableVisit
        manageWorkstay
        enableSelfRegister
        emailVerifyRequiredWhenSelfRegister
        id
        ownerMail
        paymentNoticeMailingList
        permalink
        pk
        totalPass
        domains
      }
      company {
        ...companyField
      }
      me {
        pk
        enterpriseMemberships {
          account {
            permalink
            hasVisitedDashboard
          }
          status
          emailVerified
          enabled
        }
      }
      subscription {
        status
      }
      groupPass {
        totalPass
        remainPass
      }
      totalCount
      ...accessibleSitesField
    }
  }
  ${companyField}
  ${accessibleSitesField}
`;

export interface EnterPriseAccountResult {
  enterpriseAccount: {
    permalink: string;
    pk: number;
    totalPass: number;
    availablePass: number;
    enableSelfRegister: boolean;
    emailVerifyRequiredWhenSelfRegister: boolean;
    company: CompanyType;
  };
}

export interface EnterPriseAccountQueryVariables {
  permalink: string | null;
}

export const enterPriseAccountQuery = gql`
  query EnterPriseAccount($permalink: String!) {
    enterpriseAccount(permalink: $permalink) {
      pk
      enableSelfRegister
      emailVerifyRequiredWhenSelfRegister
      availablePass
      totalPass
      company {
        ...companyField
      }
    }
  }
  ${companyField}
`;
