const WorkHistory = () => {
  return (
    <svg
      width='29'
      height='28'
      viewBox='0 0 29 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_3205_15294'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='29'
        height='28'
      >
        <rect x='0.666748' width='28' height='28' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_3205_15294)'>
        <path
          d='M12.3333 7H17V4.66667H12.3333V7ZM21.6667 26.8333C20.0528 26.8333 18.6773 26.2648 17.5402 25.1277C16.4023 23.9898 15.8333 22.6139 15.8333 21C15.8333 19.3861 16.4023 18.0102 17.5402 16.8723C18.6773 15.7352 20.0528 15.1667 21.6667 15.1667C23.2806 15.1667 24.6564 15.7352 25.7943 16.8723C26.9314 18.0102 27.5 19.3861 27.5 21C27.5 22.6139 26.9314 23.9898 25.7943 25.1277C24.6564 26.2648 23.2806 26.8333 21.6667 26.8333ZM23.5917 23.7417L24.4083 22.925L22.25 20.7667V17.5H21.0833V21.2333L23.5917 23.7417ZM14.2875 24.5H5.33333C4.69167 24.5 4.14256 24.2717 3.686 23.8152C3.22867 23.3578 3 22.8083 3 22.1667V9.33333C3 8.69167 3.22867 8.14256 3.686 7.686C4.14256 7.22867 4.69167 7 5.33333 7H10V4.66667C10 4.025 10.2287 3.4755 10.686 3.01817C11.1426 2.56161 11.6917 2.33333 12.3333 2.33333H17C17.6417 2.33333 18.1912 2.56161 18.6485 3.01817C19.1051 3.4755 19.3333 4.025 19.3333 4.66667V7H24C24.6417 7 25.1912 7.22867 25.6485 7.686C26.1051 8.14256 26.3333 8.69167 26.3333 9.33333V14.3208C25.6528 13.8347 24.9139 13.4653 24.1167 13.2125C23.3194 12.9597 22.5028 12.8333 21.6667 12.8333C19.3917 12.8333 17.462 13.6255 15.8777 15.2098C14.2926 16.7949 13.5 18.725 13.5 21C13.5 21.6222 13.5634 22.225 13.6902 22.8083C13.8162 23.3917 14.0153 23.9556 14.2875 24.5Z'
          fill='#A68E74'
        />
      </g>
    </svg>
  );
};

export default WorkHistory;
