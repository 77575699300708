import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useAccount, usePermalink } from '@/client/hooks';
import ErrorLayout from '@/layout/ErrorLayout';
import Footer from '@/layout/Footer';
import Header from '@/layout/Header';
import SideMenu from '@/layout/SideMenu';
import {
  hiddenFooterState,
  isBgWhiteState,
  isMemberState,
  isOwnerState,
} from '@/recoil/atoms';
import { colors } from '@/styles/constant';
import { currentEnterpriseMemberships } from '@/utils/enterpriseMemberships';

const GlobalLayout = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const permalink = usePermalink();

  const isSinglePage =
    router.pathname === '/' ||
    !!router.pathname.match('create') ||
    !!router.pathname.match('signup') ||
    !!router.pathname.match('invite') ||
    !!router.pathname.match('checkin') ||
    !!router.pathname.match('payments/plan/change') ||
    !!router.pathname.match('payments/plan/fail') ||
    !!router.pathname.match('payments/plan/get') ||
    !!router.pathname.match('payments/plan/info') ||
    !!router.pathname.match('payments/plan/more') ||
    !!router.pathname.match('meeting-dashboard');
  const isFullPage = !!router.pathname.match('workspaces');
  const isOnlyOwnerPage = !!router.pathname.match('payments');

  const isBgWhite = useRecoilValue(isBgWhiteState);
  const hiddenFooter = useRecoilValue(hiddenFooterState);
  const [isMember, setIsMemberState] = useRecoilState(isMemberState);
  const [isOwner, setIsOwnerState] = useRecoilState(isOwnerState);

  const { company, member, sites, component } = useAccount();

  const enterpriseMemberships =
    member?.enterpriseMemberships &&
    permalink &&
    currentEnterpriseMemberships(member.enterpriseMemberships, permalink);

  const checkMember = useCallback(() => {
    if (member?.pk) {
      setIsMemberState(true);
    } else {
      setIsMemberState(false);
    }
  }, [member, setIsMemberState]);
  const checkIsOwner = useCallback(() => {
    if (enterpriseMemberships?.status === 'OWNER') {
      setIsOwnerState(true);
    } else {
      setIsOwnerState(false);
    }
  }, [setIsOwnerState, enterpriseMemberships?.status]);

  useEffect(() => {
    checkMember();
    checkIsOwner();
  }, [checkMember, checkIsOwner]);

  if (component) return component;
  if (!isOwner && isOnlyOwnerPage) {
    return <ErrorLayout code={403} />;
  }

  if (isSinglePage) {
    return <>{children}</>;
  }

  return (
    <GlobalLayoutWrap isBgWhite={isMember || isBgWhite}>
      <Header />

      <GlobalLayoutInner>
        {isMember ? (
          <>
            {!isFullPage && (
              <SideMenuWrap>
                {permalink && company && (
                  <SideMenu
                    permalink={permalink}
                    isOwner={isOwner}
                    member={member}
                    company={company}
                    sites={sites}
                  />
                )}
              </SideMenuWrap>
            )}

            {children}
          </>
        ) : (
          children
        )}

        {!hiddenFooter && <Footer isfull={true} />}
      </GlobalLayoutInner>
    </GlobalLayoutWrap>
  );
};

export default GlobalLayout;

const GlobalLayoutWrap = styled.div<{ isBgWhite?: boolean }>`
  align-items: normal;
  background-color: ${({ isBgWhite }) =>
    isBgWhite ? colors.WHITE : colors.BG_GRAY};
  display: flex;
  flex-basis: max-content;
  flex-flow: column wrap;
  margin: auto;
  min-height: 100vh;
  overflow-x: auto;
`;

const GlobalLayoutInner = styled.div`
  min-height: calc(100vh - 60px);
  overflow-x: auto;
  position: relative;
`;

const SideMenuWrap = styled.section`
  background-color: #fcfcfc;
  border-right: 1px solid ${colors.BORDER_GRAY};
  height: 100vh;
  position: fixed;
  top: 60px;
  width: 280px;
  z-index: 10;
`;
