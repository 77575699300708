const QuestFillIcon = () => {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M14 23a9 9 0 1 0 0-18 9 9 0 0 0 0 18z' fill='#393B41' />
      <path
        d='M14.2 15.5v-.527c0-.713.37-1.38.99-1.733a2 2 0 0 0-.764-3.727A2 2 0 0 0 12.2 11.5'
        stroke='#fff'
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
      <path d='M14.201 18.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z' fill='#fff' />
    </svg>
  );
};

export default QuestFillIcon;
