import styled from '@emotion/styled';
import Image from 'next/image';
import React from 'react';

import { colors } from '@/styles/constant';
import SVG from '@/svgs';
import { imageLoader } from '@/utils/image';

export interface UserProfileProps {
  profileURL?: string | null;
  size?: number;
}

const UserProfile: React.FC<UserProfileProps> = ({ profileURL, size = 54 }) => (
  <UserProfileWrap size={size}>
    {profileURL ? (
      <Image
        loader={data => imageLoader(data)}
        src={profileURL}
        layout='fill'
        alt={profileURL}
      />
    ) : (
      <SVG.ProfileEmptyImage />
    )}
  </UserProfileWrap>
);

export default UserProfile;

const UserProfileWrap = styled.span<{ size: number }>`
  background-color: ${colors.WHITE};
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  height: ${({ size }) => `${size}px`};
  overflow: hidden;
  position: relative;
  text-align: center;
  width: ${({ size }) => `${size}px`};

  > div {
    border-radius: 50%;
    margin-top: 3px !important;
  }

  > svg {
    height: ${({ size }) => `${size}px`};
    position: relative;
    width: ${({ size }) => `${size}px`};
  }
`;
