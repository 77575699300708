const OutLinkLineIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        stroke='black'
        strokeMiterlimit='10'
        strokeWidth='2'
        d='M7.895 12.105L18 2M18 7.895V2h-5.895M16.316 10.842V18H2V3.684h7.158'
      />
    </svg>
  );
};

export default OutLinkLineIcon;
