export const currentEnterpriseMemberships = (
  enterpriseMemberships: any[],
  permalink: string,
) => {
  if (enterpriseMemberships.length > 0) {
    const currentMembership = Object.values(enterpriseMemberships).find(
      membership => membership.account.permalink === permalink,
    );

    if (currentMembership) {
      return currentMembership;
    }

    return enterpriseMemberships[0];
  }
};
