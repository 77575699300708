const HideIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='m3.567 5.458-1.9-1.9L2.725 2.5 17.5 17.275l-1.058 1.058L14 15.9l-.35-.35c-1.125.45-2.358.7-3.65.7-4.167 0-7.725-2.592-9.167-6.25A9.837 9.837 0 0 1 3.95 5.842l-.383-.384zM14.167 10c0-2.3-1.867-4.167-4.167-4.167-.541 0-1.05.109-1.525.3l-1.8-1.8a9.704 9.704 0 0 1 3.317-.583c4.166 0 7.725 2.592 9.166 6.25a9.848 9.848 0 0 1-2.858 3.958l-2.433-2.433c.191-.475.3-.983.3-1.525zM6.275 8.167l1.292 1.291A2.35 2.35 0 0 0 7.5 10c0 1.383 1.117 2.5 2.5 2.5.184 0 .367-.025.542-.067l1.292 1.292a4.13 4.13 0 0 1-1.834.442A4.168 4.168 0 0 1 5.833 10c0-.658.167-1.275.442-1.833zm6.217 1.975L9.867 7.517l.141-.009c1.384 0 2.5 1.117 2.5 2.5l-.016.134z'
        fill='#B1B5BC'
      />
    </svg>
  );
};

export default HideIcon;
