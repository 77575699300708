const PencilIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 20 20'
    >
      <g clipPath='url(#clip0)'>
        <path
          stroke='black'
          strokeMiterlimit='10'
          strokeWidth='2'
          d='M6.202 16.707l-4.473 1.714 1.713-4.473 7.299-7.302L13.5 9.409l-7.298 7.298zM15.652 7.255l-2.76-2.76 1.505-1.503c.76-.761 1.998-.761 2.76 0 .76.76.76 1.998 0 2.76l-1.505 1.503z'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <path fill='#fff' d='M0 0H20V20H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PencilIcon;
