const HolidayVillageIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_4268_15582'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='24'
        height='24'
      >
        <rect width='24' height='24' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_4268_15582)'>
        <path
          d='M2 20V10L8 4L14 10V20H2ZM4 18H7V15H9V18H12V10.825L8 6.825L4 10.825V18ZM7 13V11H9V13H7ZM16 20V9.175L10.825 4H13.65L18 8.35V20H16ZM20 20V7.525L16.475 4H19.3L22 6.7V20H20Z'
          fill='black'
        />
      </g>
    </svg>
  );
};

export default HolidayVillageIcon;
