const ArrowRightCircleIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      fill='none'
      viewBox='0 0 32 32'
    >
      <circle cx='16' cy='16' r='16' fill='black' />
      <path
        stroke='#fff'
        strokeMiterlimit='10'
        strokeWidth='2'
        d='M9 16h14M16 23l7-7-7-7'
      />
    </svg>
  );
};

export default ArrowRightCircleIcon;
