const AddPeopleIcon = () => {
  return (
    <svg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.473 9.158h5.052M16.999 11.684V6.632M13.2 8.346C13.259 7.03 12.14 6 10.834 6 9.525 6 8.392 7.039 8.467 8.346c.016.262.263 1.566.263 1.566.205 1.13.94 2.088 2.103 2.088 1.162 0 1.885-.941 2.103-2.088 0 0 .252-1.3.265-1.566zM16.665 18H5l.199-2.267c.107-.862.97-1.456 1.86-1.626l3.776-.499 3.818.511c.988.177 1.822.724 1.885 1.718L16.665 18z'
        stroke='black'
        strokeWidth='1.5'
        strokeMiterlimit='10'
      />
    </svg>
  );
};

export default AddPeopleIcon;
