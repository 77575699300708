import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { client } from './index';

import {
  checkEnterprisePermissionQuery,
  CheckEnterprisePermissionResult,
  enterPriseAccountQuery,
  EnterPriseAccountResult,
  EnterPriseAccountQueryVariables,
} from '@/client/queries/account';
import { meQuery, MeQueryResult } from '@/client/queries/me';
import ErrorLayout from '@/layout/ErrorLayout';
import LoadingLayout from '@/layout/LoadingLayout';
import { currentEnterpriseMemberships } from '@/utils/enterpriseMemberships';

export const usePermalink = () => {
  const router = useRouter();
  return router.query.hasOwnProperty('permalink')
    ? `${router.query.permalink}`.trim() || ''
    : null;
};

export const useMember = () => {
  const permalink = usePermalink();
  const { error, data, loading, ...rest } = useQuery<MeQueryResult>(meQuery);

  const member = data ? data.me : null;
  const currentEnterpriseMembership =
    permalink &&
    member &&
    currentEnterpriseMemberships(member.enterpriseMemberships, permalink);
  const accountId = currentEnterpriseMembership?.account.pk;
  const isOwner = currentEnterpriseMembership?.status == 'OWNER' ?? false;

  let component = null; // 기본적으로 null
  if (loading) {
    component = <LoadingLayout />; // 로딩중엔 전역 로딩
  } else if (error) {
    component = <ErrorLayout code={401} />;
  }

  return {
    ...rest,
    error,
    member,
    accountId,
    isOwner,
    currentEnterpriseMembership,
    loading,
    component,
  };
};

export const useAccount = () => {
  const router = useRouter();
  const permalink = usePermalink();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [data, setData] = useState<CheckEnterprisePermissionResult | null>(
    null,
  );

  const handleRequestAccountQuery = async (permalink: string) => {
    return await client
      .query({
        query: checkEnterprisePermissionQuery,
        variables: { permalink },
        fetchPolicy: 'no-cache',
      })
      .then(({ data }) => {
        setData(data);
      })
      .catch(error => {
        const message = error?.message;

        setErrorMessage(message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // refetch 정상적으로 동작 X
  // const handleRefetchQuery = async () => {
  //   await client.refetchQueries({
  //     include: [checkEnterprisePermissionQuery],
  //   });
  // };

  useEffect(() => {
    if (permalink) {
      handleRequestAccountQuery(permalink);
    } else {
      setIsLoading(false);
    }
  }, [permalink]);

  const component = isLoading ? (
    <LoadingLayout />
  ) : !permalink && !!errorMessage && router.pathname !== '/' ? (
    <ErrorLayout
      code={
        errorMessage === '권한이 없습니다.' ||
        errorMessage === '엔터프라이즈 계정이 없습니다.'
          ? 401
          : 500
      }
    />
  ) : null;

  const account = data ? data.checkEnterprisePermission?.account : null;
  const company = data ? data.checkEnterprisePermission?.company : null;
  const member = data ? data.checkEnterprisePermission?.me : null;
  const subscriptionStatus = data?.checkEnterprisePermission?.subscription
    ?.status
    ? data.checkEnterprisePermission?.subscription.status
    : null;
  const groupPass = data?.checkEnterprisePermission?.groupPass
    ? data.checkEnterprisePermission.groupPass
    : null;
  const sites = data ? data.checkEnterprisePermission?.accessibleSites : [];

  const currentEnterpriseMembership =
    permalink &&
    member &&
    currentEnterpriseMemberships(member.enterpriseMemberships, permalink);
  const isOwner = currentEnterpriseMembership?.status == 'OWNER' ?? false;
  const isAdmin = currentEnterpriseMembership?.status == 'ADMIN' ?? false;
  const membershipEnabled = currentEnterpriseMembership?.enabled;

  return {
    permalink,
    account,
    company,
    member,
    subscriptionStatus,
    groupPass,
    totalMemberCount: data?.checkEnterprisePermission?.totalCount || 0,
    sites,
    isOwner,
    isAdmin,
    membershipEnabled,
    error: !!errorMessage,
    loading: isLoading,
    component,
    refetch: handleRequestAccountQuery,
  };
};

export const useAnonymousEnterpriseAccount = () => {
  const permalink = usePermalink();
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const { data, loading } = useQuery<
    EnterPriseAccountResult,
    EnterPriseAccountQueryVariables
  >(enterPriseAccountQuery, {
    variables: { permalink },
    onCompleted: data => data.enterpriseAccount,
    onError: () => {
      setErrorMessage('다시 접속해주세요.');
    },
    skip: !permalink,
  });

  return {
    enterpriseAccount: data?.enterpriseAccount,
    permalink,
    loading,
    errorMessage,
  };
};
