const MinusCircleLineIcon = () => {
  return (
    <svg width='34' height='34' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='17' cy='17' r='16.5' fill='#fff' stroke='black' />
      <path stroke='black' strokeWidth='2' d='M11 17h12' />
    </svg>
  );
};

export default MinusCircleLineIcon;
