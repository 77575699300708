import {
  ApolloClient,
  ApolloLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  Operation,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { fromPromise } from '@apollo/client/link/utils';
import { createUploadLink } from 'apollo-upload-client';

import { TOKEN_KEY_NAME } from '../pages/api/set-tokens';

import { refreshTokenMutation } from './queries/auth';

import {
  getStorageItemOne,
  removeAllToken,
  setStorageToken,
} from '@/utils/storage';

const nonAuthHeaderOperationNames = [
  'RefreshToken',
  'RevokeToken',
  'VerifyToken',
];
let waitForFreshToken = false;
let blockForTokenRefresh = false;
let pendingRequests: any[] = []; // FIXME

export let client: ApolloClient<NormalizedCacheObject>;

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback());
  pendingRequests = [];
};

const initialization = async () => {
  removeAllToken();

  pendingRequests = [];

  try {
    await client.resetStore();
  } catch (e) {
    // pass (token이 없어서 query/mutation이 에러가 나도 무시)
  }

  // initialize 완료 후 로그인 페이지로 이동시킴
  // refresh가 만료되었으므로 재로그인이 필요한 상황
  location.href = '/';
};

const wait = () =>
  new Promise<void>(resolve => setTimeout(() => resolve(), 100));

const refreshToken = async (
  operation: Operation,
  currentRefreshToken: string | null,
) => {
  const context = operation.getContext();

  const {
    headers: { authorization, ...restHeaders },
  } = context;

  try {
    const resp = await client.mutate({
      mutation: refreshTokenMutation,
      context: {
        ...context,
        headers: {
          ...restHeaders,
        },
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
      variables: {
        refreshToken: currentRefreshToken,
      },
    });

    const { token, refreshToken } = resp.data.refreshToken;

    const autoLogin = Boolean(
      window?.localStorage.getItem(TOKEN_KEY_NAME.ACCESS),
    );

    if (autoLogin) {
      setStorageToken('local', token, refreshToken);
    } else {
      setStorageToken('session', token, refreshToken);
    }

    resolvePendingRequests();

    return token;
  } catch (e) {
    removeAllToken();

    pendingRequests = [];

    return;
  } finally {
    blockForTokenRefresh = false;
  }
};

const handlePending = () =>
  new Promise<void>(resolve => {
    pendingRequests.push(() => resolve());
  });

const uploadLink = createUploadLink({
  uri: `${process.env.NEXT_PUBLIC_ROCKETPUNCH_URL}/graphql`,
}) as unknown as ApolloLink;

const authLink = new ApolloLink((operation, forward) => {
  const token = getStorageItemOne('local', TOKEN_KEY_NAME.ACCESS);

  if (!nonAuthHeaderOperationNames.includes(operation.operationName) && token) {
    const oldHeaders = operation.getContext().headers;

    operation.setContext({
      headers: {
        ...oldHeaders,
        authorization: `JWT ${token}`,
      },
    });
  }

  return forward(operation);
});

const errorLink = onError(
  ({ graphQLErrors, operation, networkError, forward }) => {
    if (
      operation.operationName !== 'RefreshToken' &&
      graphQLErrors &&
      graphQLErrors.some(
        err => 'message' in err && err.message === 'Signature has expired',
      )
    ) {
      const currentRefreshToken = getStorageItemOne(
        'local',
        TOKEN_KEY_NAME.REFRESH,
      );

      if (currentRefreshToken) {
        let forward$;

        if (waitForFreshToken) {
          forward$ = fromPromise(
            (async () => {
              while (waitForFreshToken) {
                await wait();
              }
              return;
            })(),
          );
        } else if (!blockForTokenRefresh) {
          waitForFreshToken = true;
          blockForTokenRefresh = true;

          forward$ = fromPromise(
            (async () => {
              return await refreshToken(operation, currentRefreshToken);
            })(),
          ).filter(value => Boolean(value));
        } else {
          forward$ = fromPromise(handlePending());
        }
        return forward$.flatMap(resp => {
          const checkToken =
            resp ?? getStorageItemOne('local', TOKEN_KEY_NAME.ACCESS);

          waitForFreshToken = false;

          if (checkToken) {
            const context = operation.getContext();

            const {
              headers: { authorization, ...restHeaders },
            } = context;

            operation.setContext({
              ...context,
              headers: {
                ...restHeaders,
                authorization: `JWT ${checkToken}`,
              },
            });
          }

          return forward(operation);
        });
      } else {
        initialization();
      }

      // refresh token이 부적합한 경우 (이미 갱신완료된 폐기된 refresh token값)
    } else if (
      operation.operationName === 'RefreshToken' &&
      ((graphQLErrors && graphQLErrors.length > 0) || networkError)
    ) {
      initialization();

      // token 자체가 복호화가 안되는 경우 (부적합한 토큰을 주입한 경우)
    } else if (
      graphQLErrors &&
      graphQLErrors.some(
        err => 'message' in err && err.message === 'Error decoding signature',
      )
    ) {
      initialization();
    }
  },
);

client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authLink, errorLink, uploadLink]),
});
