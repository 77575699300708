const InfoIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        stroke='black'
        strokeMiterlimit='10'
        strokeWidth='1.5'
        d='M10 19c4.97 0 9-4.03 9-9s-4.03-9-9-9-9 4.03-9 9 4.03 9 9 9z'
      />
      <path
        fill='black'
        d='M10.001 6.87c.433 0 .783-.35.783-.782 0-.432-.35-.783-.783-.783-.432 0-.782.35-.782.783 0 .432.35.782.782.782z'
      />
      <path
        stroke='black'
        strokeMiterlimit='10'
        strokeWidth='1.5'
        d='M10 14.305V8.827H8.437M8.436 14.306h3.13'
      />
    </svg>
  );
};

export default InfoIcon;
