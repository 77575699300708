const CalendarAddIcon = () => {
  return (
    <svg
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.9472 18.1666V15.5416H10.3156V13.7916H12.9472V11.1666H14.7016V13.7916H17.3332V15.5416H14.7016V18.1666H12.9472ZM2.42089 16.4166C1.93843 16.4166 1.52528 16.2454 1.18142 15.903C0.838141 15.56 0.666504 15.1479 0.666504 14.6666V4.16663C0.666504 3.68538 0.838141 3.27354 1.18142 2.93113C1.52528 2.58813 1.93843 2.41663 2.42089 2.41663H3.29808V0.666626H5.05247V2.41663H10.3156V0.666626H12.07V2.41663H12.9472C13.4297 2.41663 13.8428 2.58813 14.1867 2.93113C14.53 3.27354 14.7016 3.68538 14.7016 4.16663V9.50413C14.4092 9.46038 14.1168 9.4385 13.8244 9.4385C13.532 9.4385 13.2396 9.46038 12.9472 9.50413V7.66663H2.42089V14.6666H8.56124C8.56124 14.9583 8.58317 15.25 8.62703 15.5416C8.67089 15.8333 8.7513 16.125 8.86826 16.4166H2.42089ZM2.42089 5.91663H12.9472V4.16663H2.42089V5.91663Z'
        fill='black'
      />
    </svg>
  );
};

export default CalendarAddIcon;
