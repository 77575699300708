const TimeIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='36'
      height='36'
      fill='none'
      viewBox='0 0 36 36'
    >
      <path
        fill='black'
        d='M18 31.5c7.456 0 13.5-6.044 13.5-13.5S25.456 4.5 18 4.5 4.5 10.544 4.5 18 10.544 31.5 18 31.5z'
      />
      <path
        stroke='#fff'
        strokeMiterlimit='10'
        strokeWidth='2'
        d='M18.586 8.021v10.566h-7.043'
      />
    </svg>
  );
};

export default TimeIcon;
