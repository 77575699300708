const PeopleBorderIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 3.333A3.332 3.332 0 1 0 10 10a3.332 3.332 0 1 0 0-6.667zm1.667 3.334C11.667 5.75 10.917 5 10 5c-.916 0-1.666.75-1.666 1.667 0 .916.75 1.666 1.666 1.666.917 0 1.667-.75 1.667-1.666zM15 15c-.166-.592-2.75-1.667-5-1.667-2.242 0-4.808 1.067-5 1.667h10zM3.334 15c0-2.217 4.441-3.333 6.666-3.333 2.225 0 6.667 1.116 6.667 3.333v1.667H3.333V15z'
        fill='#B1B5BC'
      />
    </svg>
  );
};

export default PeopleBorderIcon;
