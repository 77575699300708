import styled from '@emotion/styled';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { colors } from '@/styles/constant';
import SVG from '@/svgs';

interface LinkProps {
  isActive?: boolean;
  isSub?: boolean;
}

interface MenuItemProps {
  href: string;
  postfix: string;
  icon: JSX.Element;
  name: string;
  targetBlank?: boolean;
  subMenu?: { name: string; href: string; postfix: string }[];
}

export const LeftMenu = styled.nav`
  border-top: 2px solid #f5f6f7;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px - 94px);
  justify-content: space-between;
  padding: 17px 34px 37px;
  gap: 32px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0px 0px 5px transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
    border: 2px solid transparent;
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
    &:active {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
`;

export const MenuItem: React.FC<MenuItemProps> = ({
  href,
  postfix,
  icon,
  name,
  targetBlank,
  subMenu,
}) => {
  const router = useRouter();
  const asPath = router.asPath;
  const isActive = !!asPath.match(postfix);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const SubMenuComp = subMenu?.map(subMenu => {
    const isActive = !!asPath.match(subMenu.postfix);

    return (
      <ListItem key={subMenu.name} isSub>
        <Link href={subMenu.href} passHref>
          <MenuLink isActive={isActive} isSub>
            <TextContainer isSub>{subMenu.name}</TextContainer>
          </MenuLink>
        </Link>
      </ListItem>
    );
  });

  const handleToggleSubList = (e: any) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <ListItem>
      {['meeting', 'history', 'invitation'].includes(postfix) ? (
        <MenuLink
          target={targetBlank ? '_blank' : '_self'}
          onClick={handleToggleSubList}
        >
          <IconContainer>{icon}</IconContainer>
          <TextContainer isOpen={isOpen}>
            <span>{name}</span>
            {SubMenuComp && (
              <button onClick={handleToggleSubList}>
                <SVG.DropDownArrowIcon />
              </button>
            )}
          </TextContainer>
        </MenuLink>
      ) : (
        <Link href={href} passHref>
          <MenuLink
            target={targetBlank ? '_blank' : '_self'}
            isActive={isActive}
          >
            <IconContainer>{icon}</IconContainer>
            <TextContainer isOpen={isOpen}>
              <span>{name}</span>
              {SubMenuComp && (
                <button onClick={handleToggleSubList}>
                  <SVG.DropDownArrowIcon />
                </button>
              )}
            </TextContainer>
          </MenuLink>
        </Link>
      )}
      {isOpen && SubMenuComp && <ul>{SubMenuComp}</ul>}
    </ListItem>
  );
};

const ListItem = styled.li<{ isSub?: boolean }>`
  ${({ isSub }) => (isSub ? 'margin: 20px 0 0 52px' : 'margin-top: 20px;')};

  > ul {
    position: relative;
    &::before {
      background-color: #f5f6f7;
      content: '';
      height: 100%;
      left: 10px;
      position: absolute;
      width: 2px;
    }
  }
`;
const IconContainer = styled.span<{ isSub?: boolean }>`
  cursor: pointer;
  display: inline-block;
  height: 24px;
  margin-right: 26px;
  vertical-align: top;

  > svg {
    height: 24px;
    width: 24px;
  }
`;
const TextContainer = styled.span<{ isOpen?: boolean; isSub?: boolean }>`
  align-items: center;
  cursor: pointer;
  flex: 100%;
  line-height: 24px;
  ${({ isSub }) =>
    isSub
      ? 'display: block; text-overflow: ellipsis; white-space: nowrap;'
      : 'display: flex; justify-content: space-between;'};
  overflow: hidden;

  > button {
    height: 20px;
    ${({ isOpen }) => !isOpen && 'transform: rotate(180deg)'};
    padding: 0 2px;

    &:hover {
      svg path[stroke='black'] {
        stroke: #646b79;
      }
    }
  }
`;

const MenuLink = styled.a<LinkProps>`
  align-items: center;
  color: ${({ isActive }) => (isActive ? colors.BROWN_DARK_NEW : '#646b79')};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: ${({ isSub }) => (isSub ? 'normal' : 500)};
  line-height: 1.57;
  text-decoration: none;

  &:hover {
    color: ${colors.DARK_GRAY};
  }
  & svg > g[stroke='black'],
  & svg > path[stroke='black'] {
    stroke: ${({ isActive }) => (isActive ? colors.BROWN : colors.GRAY)};
  }
  & svg > g[fill='black'],
  & svg > path[fill='black'],
  & svg > g > path[fill='black'] {
    fill: ${({ isActive }) => (isActive ? colors.BROWN : colors.GRAY)};
  }
  &:hover svg > g[stroke='black'],
  &:hover svg > path[stroke='black'] {
    stroke: ${colors.DARK_GRAY};
  }
  &:hover svg > g[fill='black'],
  &:hover svg > path[fill='black'],
  &:hover svg > g > path[fill='black'] {
    fill: ${colors.DARK_GRAY};
  }
`;
