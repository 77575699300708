const PlusLineIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.833 10.833h-5v5H9.166v-5h-5V9.167h5v-5h1.667v5h5v1.666z'
        fill='black'
      />
    </svg>
  );
};

export default PlusLineIcon;
