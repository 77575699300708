const AddProfileIcon = () => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23.087 8.7619H20.0261L18.3043 6.85714H12.5652V8.7619H17.4435L19.2609 10.6667H23.087V22.0952H7.78261V13.5238H5.86957V22.0952C5.86957 23.1429 6.73043 24 7.78261 24H23.087C24.1391 24 25 23.1429 25 22.0952V10.6667C25 9.61905 24.1391 8.7619 23.087 8.7619ZM10.6522 16.381C10.6522 20.619 15.8078 22.7524 18.8209 19.7524C21.8339 16.7524 19.6913 11.619 15.4348 11.619C12.7948 11.619 10.6522 13.7524 10.6522 16.381ZM15.4348 13.5238C17.0035 13.5714 18.2565 14.819 18.3043 16.381C18.2565 17.9429 17.0035 19.1905 15.4348 19.2381C13.8661 19.1905 12.613 17.9429 12.5652 16.381C12.613 14.819 13.8661 13.5714 15.4348 13.5238ZM7.78261 8.7619H10.6522V6.85714H7.78261V4H5.86957V6.85714H3V8.7619H5.86957V11.619H7.78261'
        fill='#B1B5BC'
      />
    </svg>
  );
};

export default AddProfileIcon;
