import { gql } from '@apollo/client';

const updateEnterpriseMemberField = gql`
  fragment updateEnterpriseMemberField on UpdateEnterpriseMember {
    member {
      phoneCountryCode
      phone
      email
      name
      department
      position
      status
    }
  }
`;
const addEnterpriseMemberField = gql`
  fragment addEnterpriseMemberField on AddEnterpriseMember {
    member {
      phoneCountryCode
      phone
      email
      name
      department
      position
      status
    }
  }
`;

export type EnterpriseMemberStatus = 'OWNER' | 'ADMIN' | 'MEMBER';
export const accessAllowedMemberStatus: EnterpriseMemberStatus[] = [
  'OWNER',
  'ADMIN',
];

export interface EnterpriseAccountInputType {
  companyId: number;
  companyName: string;
  phoneCountryCode?: string;
  phone?: string;
  email?: string;
  department?: string;
  position?: string;
}

export interface EnterpriseMemberInputType {
  phoneCountryCode?: string;
  phone?: string;
  email?: string;
  name?: string;
  department?: string;
  position?: string;
  status?: EnterpriseMemberStatus;
}

export interface EnterpriseAccount {
  pk: number;
  availablePass: number;
  totalPass: number;
  paymentNoticeMailingList: string[];
  ownerMail?: string;
  permalink: string;
}

export interface EnterpriseMember {
  account: number;
  user: number;
  phoneCountryCode?: string;
  phone: string;
  email?: string;
  name: string;
  department?: string;
  position?: string;
  status: EnterpriseMemberStatus;
  cdate: string;
  mdate: string;
}

export interface AddEnterpriseMemberMutationVariables {
  accountId: number;
  member: EnterpriseMemberInputType;
}

export interface AddEnterpriseMemberMutationResult {
  member: EnterpriseMember;
}

export interface EnterpriseMemberImportErrorType {
  error: string;
  line: number;
}

export interface AddEnterpriseMemberBulkMutationResult {
  errors: EnterpriseMemberImportErrorType[];
  members: EnterpriseMember[];
}

export interface AddEnterpriseMemberBulkMutationVariables {
  accountId: number;
  members: EnterpriseMemberInputType[];
}

export interface EnterpriseMembersEdgesType {
  node: {
    id: string;
    pk: number;
    phoneCountryCode: string;
    phone: string;
    email: string;
    name: string;
    department: string;
    position: string;
    status: string;
    cdate: string;
    mdate: string;
    enabled: boolean;
    user: {
      id: string;
      pk: number;
      name: string;
      username: string;
      email: string;
      imageUrl: string;
      phone: string;
      phoneCountryCode: string;
    };
  };
}

export const addEnterpriseAccountMutation = gql`
  mutation AddEnterpriseAccount($account: EnterpriseAccountInputType!) {
    addEnterpriseAccount(account: $account) {
      account {
        pk
        availablePass
        totalPass
        paymentNoticeMailingList
        ownerMail
        permalink
      }
      member {
        phone
        pk
      }
    }
  }
`;

export const deleteEnterpriseAccountMutation = gql`
  mutation DeleteEnterpriseAccount($accountId: Int!) {
    deleteEnterpriseAccount(accountId: $accountId) {
      ok
    }
  }
`;

export interface EnterpriseMembersQueryResult {
  enterpriseMembers: {
    totalCount: number;
    edges: EnterpriseMembersEdgesType[];
  };
}
export interface EnterpriseMembersQueryVariables {
  accountId?: number;
  after?: string | null;
  before?: string | null;
  department?: string | null;
  first?: number | null;
  last?: number | null;
  memberId?: number | null;
  name?: string | null;
  enabled?: boolean | null;
  offset?: number | null;
  order?: string | null;
  position?: string | null;
  reservationExists?: boolean | null;
  sort?: string | null;
  status?: string | null;
  userExists?: boolean | null;
}
export const enterpriseMembersQuery = gql`
  query EnterpriseMembers(
    $after: String
    $before: String
    $accountId: Int!
    $department: String
    $first: Int
    $last: Int
    $memberId: Int
    $name: String
    $enabled: Boolean
    $offset: Int
    $order: String = "asc"
    $position: String
    $reservationExists: Boolean
    $sort: String = "name"
    $status: EnterpriseMemberStatusEnum
    $userExists: Boolean
  ) {
    enterpriseMembers(
      after: $after
      before: $before
      accountId: $accountId
      department: $department
      first: $first
      last: $last
      memberId: $memberId
      name: $name
      enabled: $enabled
      offset: $offset
      order: $order
      position: $position
      reservationExists: $reservationExists
      sort: $sort
      status: $status
      userExists: $userExists
    ) {
      totalCount
      edges {
        node {
          id
          pk
          phoneCountryCode
          phone
          email
          name
          department
          position
          status
          cdate
          mdate
          enabled
          user {
            id
            pk
            name
            username
            email
            imageUrl
            phone
            phoneCountryCode
          }
        }
      }
    }
  }
`;

export const exportEnterpriseMembersQuery = gql`
  query ExportEnterpriseMembers(
    $accountId: Int!
    $department: String
    $name: String
    $position: String
    $reservationExists: Boolean
    $status: EnterpriseMemberStatusEnum
    $userExists: Boolean
    $enabled: Boolean
  ) {
    exportEnterpriseMembers(
      accountId: $accountId
      department: $department
      name: $name
      position: $position
      reservationExists: $reservationExists
      status: $status
      userExists: $userExists
      enabled: $enabled
    )
  }
`;

export interface EnterpriseMemberFiltersQueryResult {
  enterpriseMemberFilters: {
    departments: string[];
    positions: string[];
  };
}
export interface EnterpriseMemberFiltersQueryVariables {
  accountId?: number;
}
export const enterpriseMemberFiltersQuery = gql`
  query EnterpriseMemberFilters($accountId: Int!) {
    enterpriseMemberFilters(accountId: $accountId) {
      departments
      positions
    }
  }
`;

export interface AddEnterpriseAccountMutationVariables {
  account: EnterpriseAccountInputType;
}
export interface AddEnterpriseAccountMutationResult {
  addEnterpriseAccount: {
    account: EnterpriseAccount;
    member: {
      pk: number;
      phone: string;
    };
  };
}
export const addEnterpriseMemberMutation = gql`
  mutation AddEnterpriseMember(
    $accountId: Int!
    $member: EnterpriseMemberInputType!
  ) {
    addEnterpriseMember(accountId: $accountId, member: $member) {
      ...addEnterpriseMemberField
    }
  }
  ${addEnterpriseMemberField}
`;

export const addEnterpriseMemberBulkMutation = gql`
  mutation AddEnterpriseMemberBulk(
    $accountId: Int!
    $members: [EnterpriseMemberInputType]!
  ) {
    addEnterpriseMemberBulk(accountId: $accountId, members: $members) {
      members {
        name
        phone
        phoneCountryCode
        email
        department
        position
        status
      }
      errors {
        line
        error
      }
    }
  }
`;

export interface UpdateEnterpriseMemberMutationResult {
  updateEnterpriseMember: {
    member: EnterpriseMember;
  };
}
export interface UpdateEnterpriseMemberMutationVariables {
  accountId?: number;
  memberId?: number;
  member: EnterpriseMemberInputType;
}
export const updateEnterpriseMemberMutation = gql`
  mutation UpdateEnterpriseMember(
    $accountId: Int!
    $memberId: Int!
    $member: EnterpriseMemberInputType!
  ) {
    updateEnterpriseMember(
      accountId: $accountId
      memberId: $memberId
      member: $member
    ) {
      ...updateEnterpriseMemberField
    }
  }
  ${updateEnterpriseMemberField}
`;

export const deleteEnterpriseMemberMutation = gql`
  mutation DeleteEnterpriseMember($accountId: Int!, $memberId: Int!) {
    deleteEnterpriseMember(accountId: $accountId, memberId: $memberId) {
      ok
    }
  }
`;

export const updateEnterpriseMemberGroupPassMutation = gql`
  mutation UpdateEnterpriseMemberGroupPass(
    $accountId: Int!
    $memberId: Int!
    $groupPass: Boolean!
  ) {
    updateEnterpriseMemberGroupPass(
      accountId: $accountId
      memberId: $memberId
      groupPass: $groupPass
    ) {
      ok
    }
  }
`;

export const updateEnterpriseEmailSettingsMutation = gql`
  mutation updateEnterpriseEmailSettings(
    $accountId: Int!
    $memberId: Int!
    $settings: EnterpriseEmailSettingsInputType!
  ) {
    updateEnterpriseEmailSettings(
      accountId: $accountId
      memberId: $memberId
      settings: $settings
    ) {
      settings {
        accountChanges
        extraChargeAlert
        memberChanges
        newsletter
      }
    }
  }
`;

export const resendEnterpriseMemberEmailVerificationMutation = gql`
  mutation ResendEnterpriseMemberEmailVerification($memberId: Int!) {
    resendEnterpriseMemberEmailVerification(memberId: $memberId) {
      ok
    }
  }
`;

export const updateEnterpriseMemberEmailMutation = gql`
  mutation UpdateEnterpriseMemberEmail(
    $email: String!
    $enterpriseAccountId: Int!
    $memberId: Int!
  ) {
    updateEnterpriseMemberEmail(
      email: $email
      enterpriseAccountId: $enterpriseAccountId
      memberId: $memberId
    ) {
      ok
    }
  }
`;

export const verifyEnterpriseMemberEmailMutation = gql`
  mutation VerifyEnterpriseMemberEmail(
    $code: String!
    $email: String!
    $memberId: Int!
  ) {
    verifyEnterpriseMemberEmail(
      code: $code
      email: $email
      memberId: $memberId
    ) {
      ok
    }
  }
`;

export const resendEnterpriseMemberPhoneVerificationMutation = gql`
  mutation ResendEnterpriseMemberPhoneVerification($memberId: Int!) {
    resendEnterpriseMemberPhoneVerification(memberId: $memberId) {
      ok
    }
  }
`;

export const updateEnterpriseMemberPhoneMutation = gql`
  mutation RequesetVerifyEnterpriseMemberPhone(
    $enterpriseAccountId: Int!
    $memberId: Int!
    $countryCode: String!
    $phone: String!
  ) {
    requesetVerifyEnterpriseMemberPhone(
      enterpriseAccountId: $enterpriseAccountId
      memberId: $memberId
      countryCode: $countryCode
      phone: $phone
    ) {
      ok
    }
  }
`;

export const verifyEnterpriseMemberPhoneMutation = gql`
  mutation VerifyEnterpriseMemberPhone(
    $code: String!
    $enterpriseAccountId: Int!
    $memberId: Int!
  ) {
    verifyEnterpriseMemberPhone(
      code: $code
      enterpriseAccountId: $enterpriseAccountId
      memberId: $memberId
    ) {
      ok
    }
  }
`;

export interface ToggleEnterpriseMemberMutationVariables {
  accountId: number;
  memberId: number;
}
export interface DisableEnterpriseMemberMutationResult {
  disableEnterpriseMember: { ok: boolean };
}
export interface EnableEnterpriseMemberMutationResult {
  enableEnterpriseMember: { ok: boolean };
}
export const disableEnterpriseMemberMutation = gql`
  mutation DisableEnterpriseMember($accountId: Int!, $memberId: Int!) {
    disableEnterpriseMember(accountId: $accountId, memberId: $memberId) {
      ok
    }
  }
`;
export const enableEnterpriseMemberMutation = gql`
  mutation EnableEnterpriseMember($accountId: Int!, $memberId: Int!) {
    enableEnterpriseMember(accountId: $accountId, memberId: $memberId) {
      ok
    }
  }
`;
