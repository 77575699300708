const DropDownArrowIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        stroke='black'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='2'
        d='M4 13l6-6 6 6'
      />
    </svg>
  );
};

export default DropDownArrowIcon;
