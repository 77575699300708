const DangerIcon = () => {
  // Do not touch fill color
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        stroke='black'
        strokeMiterlimit='10'
        strokeWidth='1.5'
        d='M10 2.5l8.333 14.167H1.666L9.999 2.5zM10 7.56v4.722'
      />
      <path
        fill='#EC506A'
        d='M10 14.643c.383 0 .694-.302.694-.675 0-.373-.311-.675-.695-.675-.383 0-.694.303-.694.675 0 .373.31.675.694.675z'
      />
    </svg>
  );
};

export default DangerIcon;
