const DeleteIcon = () => {
  return (
    <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='.3'
        d='M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16z'
        fill='#393B41'
      />
      <path d='m7 7 6 6M7 13l6-6' stroke='#fff' strokeMiterlimit='10' />
    </svg>
  );
};

export default DeleteIcon;
