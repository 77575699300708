const MeetingIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 16.5c-.7 0-1.292-.242-1.775-.725C6.742 15.292 6.5 14.7 6.5 14s.242-1.292.725-1.775C7.708 11.742 8.3 11.5 9 11.5s1.292.242 1.775.725c.483.483.725 1.075.725 1.775s-.242 1.292-.725 1.775c-.483.483-1.075.725-1.775.725zM5 22c-.55 0-1.021-.196-1.413-.587A1.928 1.928 0 0 1 3 20V6c0-.55.196-1.02.587-1.412A1.927 1.927 0 0 1 5 4h1V2h2v2h8V2h2v2h1c.55 0 1.021.196 1.413.588.391.391.587.862.587 1.412v14c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 19 22H5zm0-2h14V10H5v10zM5 8h14V6H5v2z'
        fill='black'
      />
    </svg>
  );
};

export default MeetingIcon;
