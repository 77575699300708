const PeopleFillIcon = () => {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_3205_15280'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='28'
        height='28'
      >
        <rect width='28' height='28' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_3205_15280)'>
        <path
          d='M1.16675 23.3333V20.0667C1.16675 19.4056 1.33708 18.7977 1.67775 18.2432C2.01764 17.6894 2.46953 17.2667 3.03341 16.975C4.23897 16.3722 5.46397 15.9199 6.70841 15.6182C7.95286 15.3172 9.21675 15.1667 10.5001 15.1667C11.7834 15.1667 13.0473 15.3172 14.2917 15.6182C15.5362 15.9199 16.7612 16.3722 17.9667 16.975C18.5306 17.2667 18.9825 17.6894 19.3224 18.2432C19.6631 18.7977 19.8334 19.4056 19.8334 20.0667V23.3333H1.16675ZM22.1667 23.3333V19.8333C22.1667 18.9778 21.9287 18.1561 21.4527 17.3682C20.976 16.5811 20.3001 15.9056 19.4251 15.3417C20.4167 15.4583 21.3501 15.6574 22.2251 15.939C23.1001 16.2213 23.9167 16.5667 24.6751 16.975C25.3751 17.3639 25.9098 17.7963 26.2792 18.2723C26.6487 18.7491 26.8334 19.2694 26.8334 19.8333V23.3333H22.1667ZM10.5001 14C9.21675 14 8.11814 13.5431 7.20425 12.6292C6.29036 11.7153 5.83341 10.6167 5.83341 9.33333C5.83341 8.05 6.29036 6.95139 7.20425 6.0375C8.11814 5.12361 9.21675 4.66667 10.5001 4.66667C11.7834 4.66667 12.882 5.12361 13.7959 6.0375C14.7098 6.95139 15.1667 8.05 15.1667 9.33333C15.1667 10.6167 14.7098 11.7153 13.7959 12.6292C12.882 13.5431 11.7834 14 10.5001 14ZM22.1667 9.33333C22.1667 10.6167 21.7098 11.7153 20.7959 12.6292C19.882 13.5431 18.7834 14 17.5001 14C17.2862 14 17.014 13.9759 16.6834 13.9277C16.3529 13.8787 16.0806 13.825 15.8667 13.7667C16.3917 13.1444 16.795 12.4542 17.0766 11.6958C17.3589 10.9375 17.5001 10.15 17.5001 9.33333C17.5001 8.51667 17.3589 7.72917 17.0766 6.97083C16.795 6.2125 16.3917 5.52222 15.8667 4.9C16.139 4.80278 16.4112 4.73939 16.6834 4.70983C16.9556 4.68106 17.2279 4.66667 17.5001 4.66667C18.7834 4.66667 19.882 5.12361 20.7959 6.0375C21.7098 6.95139 22.1667 8.05 22.1667 9.33333Z'
          fill='#A68E74'
        />
      </g>
    </svg>
  );
};

export default PeopleFillIcon;
