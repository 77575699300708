import { TOKEN_KEY_NAME } from 'src/pages/api/set-tokens';

type StorageType = 'local' | 'session';

export const getStorageItemOne = (priority: StorageType, key: string) => {
  if (priority === 'local') {
    return (
      window?.localStorage.getItem(key) ?? window?.sessionStorage.getItem(key)
    );
  }
  return (
    window?.sessionStorage.getItem(key) ?? window?.localStorage.getItem(key)
  );
};

export const setStorageToken = (
  type: StorageType,
  token: string,
  refreshToken: string,
) => {
  if (type === 'local') {
    window?.localStorage.setItem(TOKEN_KEY_NAME.ACCESS, token);
    window?.localStorage.setItem(TOKEN_KEY_NAME.REFRESH, refreshToken);
  } else {
    window?.sessionStorage.setItem(TOKEN_KEY_NAME.ACCESS, token);
    window?.sessionStorage.setItem(TOKEN_KEY_NAME.REFRESH, refreshToken);
  }
};

export const removeAllToken = () => {
  window?.localStorage.removeItem(TOKEN_KEY_NAME.ACCESS);
  window?.localStorage.removeItem(TOKEN_KEY_NAME.REFRESH);
  window?.sessionStorage.removeItem(TOKEN_KEY_NAME.ACCESS);
  window?.sessionStorage.removeItem(TOKEN_KEY_NAME.REFRESH);
};
