const PassIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='black'
        d='M23 10v1c.552 0 1-.448 1-1h-1zm0 4h1c0-.552-.448-1-1-1v1zM1 14v-1c-.552 0-1 .448-1 1h1zm0-4H0c0 .552.448 1 1 1v-1zm15-2c0-.552-.448-1-1-1s-1 .448-1 1h2zm-2 8c0 .552.448 1 1 1s1-.448 1-1h-2zM2 4C.895 4 0 4.895 0 6h2V4zm20 0H2v2h20V4zm2 2c0-1.105-.895-2-2-2v2h2zm0 4V6h-2v4h2zm-2 2c0-.552.448-1 1-1V9c-1.657 0-3 1.343-3 3h2zm1 1c-.552 0-1-.448-1-1h-2c0 1.657 1.343 3 3 3v-2zm1 5v-4h-2v4h2zm-2 2c1.105 0 2-.895 2-2h-2v2zM2 20h20v-2H2v2zm-2-2c0 1.105.895 2 2 2v-2H0zm0-4v4h2v-4H0zm2-2c0 .552-.448 1-1 1v2c1.657 0 3-1.343 3-3H2zm-1-1c.552 0 1 .448 1 1h2c0-1.657-1.343-3-3-3v2zM0 6v4h2V6H0zm14 2v8h2V8h-2z'
      />
    </svg>
  );
};

export default PassIcon;
