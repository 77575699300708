const DropDownBoldIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      fill='none'
      viewBox='0 0 40 40'
    >
      <rect
        width='38'
        height='38'
        x='39'
        y='1'
        fill='#fff'
        stroke='black'
        strokeWidth='2'
        rx='3'
        transform='rotate(90 39 1)'
      />
      <path
        stroke='black'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='2'
        d='M26 24l-6-6-6 6'
      />
    </svg>
  );
};

export default DropDownBoldIcon;
