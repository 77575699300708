const TimeLineIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.992 1.667c-4.6 0-8.325 3.733-8.325 8.333s3.724 8.333 8.325 8.333c4.608 0 8.341-3.733 8.341-8.333S14.6 1.667 9.992 1.667zm.008 15A6.665 6.665 0 0 1 3.333 10 6.665 6.665 0 0 1 10 3.333 6.665 6.665 0 0 1 16.666 10 6.665 6.665 0 0 1 10 16.667zM9.166 5.833h1.25v4.375l3.75 2.225-.625 1.025-4.375-2.625v-5z'
        fill='#646B79'
      />
    </svg>
  );
};

export default TimeLineIcon;
