const FolderIcon = () => {
  // Do not touch fill color
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      fill='none'
      viewBox='0 0 40 40'
    >
      <path fill='#A1856E' d='M5.2 15.6V6H18l3.2 4.8h14.399v4.8' />
      <path
        fill='#B5967C'
        d='M31.2 38H9.368c-3.328 0-6.096-2.552-6.376-5.864L2 15.6h36.8l-1.224 16.536C37.296 35.448 34.528 38 31.2 38z'
      />
    </svg>
  );
};

export default FolderIcon;
