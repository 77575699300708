const ClockIcon = () => {
  return (
    <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 17a7 7 0 1 0 0-14 7 7 0 0 0 0 14z'
        stroke='#A1856E'
        strokeWidth='1.4'
        strokeMiterlimit='10'
      />
      <path
        d='M10 6v4H7'
        stroke='#A1856E'
        strokeWidth='1.4'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ClockIcon;
