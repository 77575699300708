import React, { useReducer, createContext } from 'react';
import { Dispatch } from 'react';

export interface ToastType {
  id: number;
  message: string;
}
interface StateTypes {
  toast: ToastType[];
  getPlan: boolean;
}
interface ContextProps {
  contextState: StateTypes;
  contextDispatch: Dispatch<ContextActionsProps>;
}

const Context = createContext<ContextProps>({} as ContextProps);

const Provider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(contextReducer, initContextState);

  return (
    <Context.Provider
      value={{ contextState: state, contextDispatch: dispatch }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context, Provider };

type ContextActionsProps =
  | { type: ContextActions.ADD_TOAST; value: string }
  | { type: ContextActions.REMOVE_TOAST; value: number }
  | { type: ContextActions.CLEAN_TOAST }
  | { type: ContextActions.SHOW_GET_PLAN }
  | { type: ContextActions.HIDE_GET_PLANM };
export enum ContextActions {
  ADD_TOAST,
  REMOVE_TOAST,
  CLEAN_TOAST,
  SHOW_GET_PLAN,
  HIDE_GET_PLANM,
}

const initContextState = {
  toast: [],
  getPlan: false,
};

const contextReducer = (state: StateTypes, action: ContextActionsProps) => {
  switch (action.type) {
    case ContextActions.ADD_TOAST: {
      const newToastMessage = action.value;

      const stateToast = state.toast;
      const overToast = stateToast.length > 2;

      overToast && stateToast.pop();

      const newToast = { id: +new Date(), message: newToastMessage };
      const updateToast = [newToast, ...stateToast];
      return { ...state, toast: updateToast };
    }
    case ContextActions.REMOVE_TOAST: {
      const id = action.value;
      const updateToast = state.toast.filter(toast => toast.id !== id);
      return { ...state, toast: updateToast };
    }
    case ContextActions.CLEAN_TOAST: {
      return { ...state, toast: initContextState.toast };
    }
    case ContextActions.SHOW_GET_PLAN: {
      return { ...state, getPlan: true };
    }
    case ContextActions.HIDE_GET_PLANM: {
      return { ...state, getPlan: false };
    }

    default: {
      return state;
    }
  }
};
