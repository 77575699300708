import { atom } from 'recoil';

export const isMemberState = atom({
  key: 'isMember',
  default: false,
});

export const isOwnerState = atom({
  key: 'isOwner',
  default: false,
});

export const isBgWhiteState = atom({
  key: 'isBgWhite',
  default: false,
});

export const hiddenFooterState = atom({
  key: 'hiddenFooter',
  default: false,
});

export const headerTypeState = atom({
  key: 'headerTypeState',
  default: false,
});

export enum HeaderTypes {}
