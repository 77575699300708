export const imageLoader = (
  data: {
    src: string;
    width: number;
    quality?: number;
  },
  isOnlySrc?: boolean,
) => {
  return isOnlySrc
    ? data.src
    : `${data.src}?w=${data.width}&q=${data?.quality || 75}`;
};
