const SearchIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 20 20'
    >
      <circle cx='9' cy='9' r='7' stroke='black' strokeWidth='2' />
      <path
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M14 14l4 4'
      />
    </svg>
  );
};

export default SearchIcon;
