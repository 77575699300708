import styled from '@emotion/styled';
import React from 'react';

import { colors } from '@/styles/constant';

const Footer = ({ isfull }: { isfull?: boolean }) => {
  return (
    <FooterWrap isfull={isfull}>
      <div>
        <FooterList>
          <li>
            <a
              href='https://www.jibmusil.com/tos'
              target='_blank'
              rel='external noreferrer'
            >
              이용약관
            </a>
          </li>
          <li>
            <a
              href='https://www.jibmusil.com/privacy'
              target='_blank'
              rel='external noreferrer'
            >
              개인정보처리방침
            </a>
          </li>
          <li>
            <a
              href='https://www.jibmusil.com/operation_policy'
              target='_blank'
              rel='external noreferrer'
            >
              운영정책
            </a>
          </li>
          <li>
            <a
              href='mailto:knock@jibmusil.com'
              target='_blank'
              rel='noreferrer'
            >
              고객문의
            </a>
          </li>
        </FooterList>
        <FooterCopy>&copy; 2022. Alicorn, Inc. all rights reserved.</FooterCopy>
      </div>
    </FooterWrap>
  );
};

export default Footer;

const FooterWrap = styled.footer<{ isfull?: boolean }>`
  display: flex;
  justify-content: center;
  margin: ${({ isfull }) => (isfull ? '40px auto' : '40px 0')};
  max-width: 1140px;

  > div {
    text-align: center;
  }
`;
const FooterList = styled.ul`
  display: flex;

  li {
    display: flex;
    align-items: center;

    a {
      color: #646b79;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
      opacity: 0.75;
      text-decoration: none;
    }
  }
  li + li {
    &::before {
      background-color: ${colors.DARK_GRAY};
      content: '';
      height: 16px;
      margin: 3px 16px 0;
      opacity: 0.3;
      width: 1px;
    }
  }

  @media (max-width: 760px) {
    li + li {
      &::before {
        margin: 3px 8px 0;
      }
    }
  }
`;
const FooterCopy = styled.p`
  color: ${colors.DARK_GRAY};
  font-size: 14px;
  line-height: 1.5;
  margin-top: 12px;
  opacity: 0.5;
`;
