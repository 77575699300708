import styled from '@emotion/styled';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { useMember, usePermalink } from '@/client/hooks';
import { accessAllowedMemberStatus } from '@/client/queries/members';
import UserProfile from '@/components/insight/UserProfile';
import useLogOutHook from '@/hooks/useLogOutHook';
import { colors } from '@/styles/constant';
import SVG from '@/svgs';
import { handleMatchRoute } from '@/utils/matchRoute';

const showOnlyLogoPage = ['checkin'];

const Header: React.FC = () => {
  const router = useRouter();
  const { member } = useMember();
  const permalink = usePermalink();
  const [myMenuOpen, setMyMenuOpen] = useState<boolean>(false);
  const { handleLogOut } = useLogOutHook();

  const enterpriseMembership = member?.enterpriseMemberships.find(item =>
    accessAllowedMemberStatus.includes(item.status),
  );
  const checkPermalink = permalink ?? enterpriseMembership?.account?.permalink;
  const showOnlyLogo = handleMatchRoute(router.asPath, showOnlyLogoPage);

  const signUpButtonComponent = (
    <JoinUsButtonWrap>
      <Link href='/signup' passHref>
        <JoinUsButton>아직 계정이 없으신가요?</JoinUsButton>
      </Link>
    </JoinUsButtonWrap>
  );

  return (
    <HeaderWrap>
      <h1>
        <Link
          href={checkPermalink ? `/${checkPermalink}/dashboard/insight` : '/'}
        >
          <a>
            <Image
              src='/assets/images/logo-jibmusil-enterprise-white.png'
              width='177'
              height='24'
              alt='執務室 enterprise'
            />
          </a>
        </Link>
      </h1>

      <div>
        {member && !showOnlyLogo ? (
          <MyMenuButton
            isOpen={myMenuOpen}
            onClick={() => setMyMenuOpen(!myMenuOpen)}
          >
            <div>
              <UserProfile profileURL={member.imageUrl} size={32} />
              <span>{member.name}</span>
              <SVG.DropDownArrowIcon />
            </div>

            {myMenuOpen && (
              <MyMenuLayer>
                <button
                  onClick={() =>
                    window.open(
                      `${process.env.NEXT_PUBLIC_ROCKETPUNCH_URL}/@${member?.username}`,
                    )
                  }
                >
                  내 정보 수정
                  <SVG.OutLinkLineIcon />
                </button>
                <button onClick={handleLogOut}>로그아웃</button>
              </MyMenuLayer>
            )}
          </MyMenuButton>
        ) : (
          !showOnlyLogo && signUpButtonComponent
        )}
      </div>
    </HeaderWrap>
  );
};

export default Header;

const HeaderWrap = styled.header`
  align-items: center;
  background: ${colors.NAVY_NEW};
  display: flex;
  height: 60px;
  justify-content: space-between;
  padding: 0 28px;
  position: fixed;
  width: 100vw;
  z-index: 10;
`;

const JoinUsButtonWrap = styled.div`
  text-align: right;
`;
const JoinUsButton = styled.a`
  background: transparent;
  border: 1px solid ${colors.WHITE};
  border-radius: 4px;
  color: ${colors.WHITE};
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
  width: 200px;

  &:hover {
    background: ${colors.BROWN};
    border-color: ${colors.BROWN};
  }
`;

const MyMenuButton = styled.button<{ isOpen: boolean }>`
  position: relative;
  vertical-align: top;

  > div {
    align-items: center;
    color: ${colors.WHITE};
    display: flex;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;

    > span {
      padding: 0 8px 0 12px;
    }

    > svg {
      ${({ isOpen }) => !isOpen && 'transform: rotate(180deg)'};

      path[stroke='black'] {
        stroke: #b1b5bc;
      }
    }

    &:hover svg path[stroke='black'] {
      stroke: ${colors.WHITE};
    }
  }
`;
const MyMenuLayer = styled.div`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  min-width: 160px;
  padding: 5px;
  position: absolute;
  right: 0;
  text-align: left;
  top: 42px;

  > button {
    align-items: center;
    border-radius: 5px;
    color: #646b79;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    justify-content: space-between;
    padding: 10px 15px;
    text-align: left;
    width: 100%;

    &:hover {
      background-color: #f5f6f7;
      color: #393b41;
    }

    svg {
      transform: scale(0.7);
      path[stroke='black'] {
        stroke: ${colors.BROWN}!important;
      }
    }
  }

  > button + button {
    margin-top: 5px;
  }
`;
