import { NextApiRequest, NextApiResponse } from 'next';

export default async function handler(
  request: NextApiRequest,
  response: NextApiResponse,
) {
  const token = request.cookies[TOKEN_KEY_NAME.ACCESS];
  const refreshToken = request.cookies[TOKEN_KEY_NAME.REFRESH];

  response.status(200).json({ token, refreshToken });
  response.end();
}

export const API_OPTION = {
  url: `${process.env.NEXT_PUBLIC_ROCKETPUNCH_URL}/graphql`,
  headers: { 'content-type': 'application/json' },
};

export const TOKEN_KEY_NAME = {
  ACCESS: 'token',
  REFRESH: 'refresh-token',
};
