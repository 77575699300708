import AddPeopleIcon from './AddPeopleIcon';
import AddProfileIcon from './AddProfileIcon';
import AngryIcon from './AngryIcon';
import ArrowLeftIcon from './ArrowLeftIcon';
import ArrowRightCircleIcon from './ArrowRightCircleIcon';
import ArrowRightIcon from './ArrowRightIcon';
import BackButtonIcon from './BackButtonIcon';
import CSVIcon from './CSVIcon';
import CalendarAddIcon from './CalendarAddIcon';
import CalendarIcon from './CalendarIcon';
import CalendarViewIcon from './CalendarViewIcon';
import CardIcon from './CardIcon';
import ChartIcon from './ChartIcon';
import CheckIcon from './CheckIcon';
import ClockIcon from './ClockIcon';
import CloseIcon from './CloseIcon';
import CompanyEmptyImage from './CompanyEmptyImage';
import DangerIcon from './DangerIcon';
import DisplayIcon from './DisplayIcon';
import DocumentIcon from './DocumentIcon';
import DownloadIcon from './DownloadIcon';
import DropDownArrowIcon from './DropDownArrowIcon';
import DropDownBoldIcon from './DropDownBoldIcon';
import DropDownNormalIcon from './DropDownNormalIcon';
import FolderIcon from './FolderIcon';
import HTTP400Image from './HTTP400Image';
import HTTP401Image from './HTTP401Image';
import HTTP403Image from './HTTP403Image';
import HTTP404Image from './HTTP404Image';
import HTTP500Image from './HTTP500Image';
import HideIcon from './HideIcon';
import HolidayVillageIcon from './HolidayVilliageIcon';
import InfoIcon from './InfoIcon';
import InviteIcon from './InviteIcon';
import LineChartLabelIcon from './LineChartLabelIcon';
import ListIcon from './ListIcon';
import ListViewIcon from './ListViewIcon';
import MarkIcon from './MarkIcon';
import MeetingIcon from './MeetingIcon';
import MinusCircleLineIcon from './MinusCircleLineIcon';
import MoreIcon from './MoreIcon';
import OutLinkLineIcon from './OutLinkLineIcon';
import PassIcon from './PassIcon';
import PencilFillIcon from './PencilFillIcon';
import PencilIcon from './PencilIcon';
import PeopleBorderIcon from './PeopleBorderIcon';
import PeopleFillIcon from './PeopleFillIcon';
import PeopleIcon from './PeopleIcon';
import PlusCircleFillIcon from './PlusCircleFillIcon';
import PlusCircleLineIcon from './PlusCircleLineIcon';
import PlusLineIcon from './PlusLineIcon';
import ProfileEmptyImage from './ProfileEmptyImage';
import QuestFillIcon from './QuestFillIcon';
import QuestLineIcon from './QuestLineIcon';
import RadioOffIcon from './RadioOffIcon';
import RadioOnIcon from './RadioOnIcon';
import RetryIcon from './RetryIcon';
import RocketPunchBeigeImage from './RocketPunchBeigeImage';
import RocketPunchGrayImage from './RocketPunchGrayImage';
import SearchIcon from './SearchIcon';
import SearchNewIcon from './SearchNewIcon';
import SendIcon from './SendIcon';
import SetUpIcon from './SetUpIcon';
import DeleteIcon from './TextDelete';
import TimeAddIcon from './TimeAddIcon';
import TimeIcon from './TimeIcon';
import TimeLineIcon from './TimeLineIcon';
import TimeResetIcon from './TimeResetIcon';
import TrashCanIcon from './TrashCanIcon';
import WorkHistory from './WorkHistory';

const SVG = {
  AddPeopleIcon,
  AddProfileIcon,
  AngryIcon,
  ArrowLeftIcon,
  ArrowRightCircleIcon,
  ArrowRightIcon,
  BackButtonIcon,
  CSVIcon,
  CalendarAddIcon,
  CalendarIcon,
  CalendarViewIcon,
  CardIcon,
  ChartIcon,
  ClockIcon,
  CloseIcon,
  CheckIcon,
  CompanyEmptyImage,
  DangerIcon,
  DisplayIcon,
  DocumentIcon,
  DownloadIcon,
  DropDownArrowIcon,
  DropDownBoldIcon,
  DropDownNormalIcon,
  FolderIcon,
  HTTP400Image,
  HTTP401Image,
  HTTP403Image,
  HTTP404Image,
  HTTP500Image,
  HolidayVillageIcon,
  InfoIcon,
  InviteIcon,
  LineChartLabelIcon,
  ListIcon,
  ListViewIcon,
  MarkIcon,
  MinusCircleLineIcon,
  MeetingIcon,
  MoreIcon,
  OutLinkLineIcon,
  PassIcon,
  PencilIcon,
  PeopleIcon,
  PlusCircleFillIcon,
  PlusCircleLineIcon,
  PlusLineIcon,
  ProfileEmptyImage,
  QuestFillIcon,
  QuestLineIcon,
  RadioOffIcon,
  RadioOnIcon,
  RetryIcon,
  RocketPunchBeigeImage,
  RocketPunchGrayImage,
  SearchIcon,
  SearchNewIcon,
  SendIcon,
  SetUpIcon,
  DeleteIcon,
  TimeAddIcon,
  TimeIcon,
  TimeResetIcon,
  TrashCanIcon,
  PencilFillIcon,
  PeopleBorderIcon,
  HideIcon,
  TimeLineIcon,
  PeopleFillIcon,
  WorkHistory,
};

export default SVG;
