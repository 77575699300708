export enum colors {
  BLUE = '#2844ee',
  PURPLE = '#5367d4',
  PINK = '#ec506a',
  RED = '#f8202d',
  STRONG_RED = '#A03C03',
  LIGHT_RED = '#DD7869',
  ORANGE = '#9f3c03',
  BROWN = '#a1856e',
  NAVY = '#232935',
  NAVY_LIGHT = '#646b79',
  DARK_GRAY = '#393b41',
  GRAY = '#c4c4c6',
  LIGHT_GRAY = '#B1B5BC',
  BG_GRAY = '#f3f4f4',
  BG_PINK = '#fef4f4',
  BORDER_GRAY = '#eee',
  BORDER_GRAY_LIGHT = '#e1e1e1',
  WHITE = '#fff',
  BLUE_NEW = '#375588',
  LIGHT_BLUE = '#ddebf9',
  MINT = '#d9f1e0',
  GREEN = '#1da142',
  LIGHT_GREEN = '#55BE6C',
  LIGHT_BROWN = '#f3e9e9',
  BEIGE = '#F8F0E1',
  // new
  RED_NEW = '#be3e2b',
  NAVY_NEW = '#232935',
  BROWN_NEW = '#8f7354',
  BROWN_DARK_NEW = '#6a5239',
  // meeting-dashboard
  RED_MEETING = '#EA6A42',
  NAVY_MEETING = '#646B79',
}
export const BarColors = [
  colors.BROWN,
  colors.BROWN,
  colors.BROWN,
  colors.BROWN,
  colors.BROWN,
  colors.GRAY,
  colors.GRAY,
];
export const doughnutColors = [
  colors.DARK_GRAY,
  colors.BROWN,
  '#919191',
  '#b0b0b0',
  '#d4d4d4',
];
