const CheckIcon = () => {
  // Do not touch fill color
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='16' cy='16' r='16' fill='#8F7354' />
      <path
        d='M13 20.17 8.83 16l-1.42 1.41L13 23l12-12-1.41-1.41L13 20.17z'
        fill='#fff'
      />
    </svg>
  );
};

export default CheckIcon;
