const TimeResetIcon = () => {
  return (
    <svg width='28' height='28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19 1.5 22 5l-3 3.5'
        stroke='#A1856E'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M22 5h-7.998C9.032 5 5 9.07 5 14.021 5 18.973 9.032 23 14.002 23c4.97 0 9.003-4.032 9.003-8.983'
        stroke='#A1856E'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M14.395 7.35v7.043H9.699'
        stroke='#A1856E'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
    </svg>
  );
};

export default TimeResetIcon;
