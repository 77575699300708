import styled from '@emotion/styled';
import add from 'date-fns/add';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import {
  AccessibleSitesType,
  CheckEnterprisePermissionMeEnterpriseType,
  CheckEnterprisePermissionMeType,
  CompanyType,
} from '@/client/queries/account';
import { LeftMenu, MenuItem } from '@/layout/dashboard/LeftMenu';
import { colors } from '@/styles/constant';
import SVG from '@/svgs';
import { changeDateToFormat, getQueryArg } from '@/utils/time';

interface SideMenuProps {
  permalink: string;
  isOwner: boolean;
  member: CheckEnterprisePermissionMeType | null;
  company: CompanyType;
  sites: AccessibleSitesType[];
}

const SideMenu: React.FC<SideMenuProps> = ({
  permalink,
  isOwner,
  member,
  company,
  sites,
}) => {
  const historyInnerMenus = getHistoryInnerMenus({ permalink, sites });
  const meetingRoomInnerMenus = getMeetingRoomInnerMenus({ permalink });
  const invitationInnerMenus = getInvitationInnerMenus({ permalink });
  const isNew = member?.enterpriseMemberships
    ?.filter(
      (membership: CheckEnterprisePermissionMeEnterpriseType) =>
        membership.account.permalink !== permalink,
    )
    .some((membership: CheckEnterprisePermissionMeEnterpriseType) => {
      return !membership.account.hasVisitedDashboard;
    });
  const checkHaveMeetingRoom = sites[0].isJibmusil === false ? true : false;

  return (
    <>
      <Link href='/workspaces' passHref>
        <CompanyInfoWrap>
          <CompanyLogoWrap isNew={isNew}>
            <div>
              {company?.logo ? (
                <Image
                  src={company.logo}
                  width={44}
                  height={44}
                  objectFit='contain'
                  objectPosition='center center'
                  alt={company.name}
                />
              ) : (
                <SVG.CompanyEmptyImage />
              )}
            </div>
          </CompanyLogoWrap>

          <CompanyDetailGroup>
            <strong>{company.name}</strong>
            {isOwner ? '소유자' : '관리자'}

            <SVG.ArrowRightIcon />
          </CompanyDetailGroup>
        </CompanyInfoWrap>
      </Link>

      <LeftMenu>
        <ul>
          <MenuItem
            href={`/${permalink}/dashboard/insight/`}
            postfix='insight'
            icon={<SVG.ChartIcon />}
            name='대시보드'
          />
          <MenuItem
            href={`/${permalink}/dashboard/members/`}
            postfix='members'
            icon={<SVG.PeopleIcon />}
            name='구성원 관리'
          />
          <MenuItem
            href={`/${permalink}/dashboard/history`}
            postfix='history'
            icon={<SVG.DocumentIcon />}
            name='이용 내역'
            subMenu={historyInnerMenus}
          />
          <MenuItem
            href={`/${permalink}/dashboard/workstay/`}
            postfix='workstay'
            icon={<SVG.HolidayVillageIcon />}
            name='워크 스테이'
          />
          {checkHaveMeetingRoom && (
            <MenuItem
              href={`/${permalink}/dashboard/meeting/schedule/`}
              postfix='meeting'
              icon={<SVG.MeetingIcon />}
              name='회의실'
              subMenu={meetingRoomInnerMenus}
            />
          )}
          <MenuItem
            href={`/${permalink}/dashboard/invitation/`}
            postfix='invitation'
            icon={<SVG.InviteIcon />}
            name='초대'
            subMenu={invitationInnerMenus}
          />
          {isOwner && (
            <MenuItem
              href={`/${permalink}/dashboard/payments/plan/`}
              postfix='payments'
              icon={<SVG.CardIcon />}
              name='플랜 및 결제'
            />
          )}
        </ul>

        <ul>
          <MenuItem
            href={`/${permalink}/dashboard/settings/workspace/`}
            postfix='settings'
            icon={<SVG.SetUpIcon />}
            name='설정'
          />
          <MenuItem
            targetBlank={true}
            href='https://help.jibmusil.com/enterprise'
            postfix='help'
            icon={<SVG.QuestLineIcon />}
            name='도움말'
          />
        </ul>
      </LeftMenu>
    </>
  );
};

export default SideMenu;

interface GetHistoryInnerMenusProps {
  permalink: string;
  sites: AccessibleSitesType[];
}
interface GetMeetingRoomInnerMenusProps {
  permalink: string;
}

const getHistoryInnerMenus = ({
  permalink,
  sites,
}: GetHistoryInnerMenusProps) => {
  const { year, month } = getQueryArg({ date: new Date() });
  const fromDate = changeDateToFormat(
    add(new Date(), {
      days: -28,
    }),
    'yyyy-MM-dd',
  );
  const toDate = changeDateToFormat(new Date(), 'yyyy-MM-dd');
  const getSites = sites?.filter(site => !site.isJibmusil);

  const defaultMenu = [
    {
      name: '이용 현황',
      href: `/${permalink}/dashboard/history/summary/?year=${year}&month=${month}&fromDate=${fromDate}&toDate=${toDate}`,
      postfix: 'summary',
    },
    {
      name: '집무실',
      href: `/${permalink}/dashboard/history/jibmusil/total/?year=${year}&month=${month}&fromDate=${fromDate}&toDate=${toDate}`,
      postfix: 'jibmusil',
    },
  ];
  // 정책상 현재는 집무실만 노출
  const officeMenus =
    getSites?.length > 0
      ? getSites.map(site => {
          const officeId = site.pk;
          const officeName = site.name;
          return {
            name: officeName,
            href: `/${permalink}/dashboard/history/office/${officeId}/${officeName}/total/?year=${year}&month=${month}&fromDate=${fromDate}&toDate=${toDate}`,
            postfix: `/office/${officeId}`,
          };
        })
      : [
          {
            name: '사옥',
            href: `/${permalink}/dashboard/history/office/landing`,
            postfix: 'landing',
          },
        ];

  return [...defaultMenu, ...officeMenus];
};
const getMeetingRoomInnerMenus = ({
  permalink,
}: GetMeetingRoomInnerMenusProps) => {
  const subMenus = [
    {
      name: '예약 현황',
      href: `/${permalink}/dashboard/meeting/schedule`,
      postfix: 'schedule',
    },
    {
      name: '회의실 정보',
      href: `/${permalink}/dashboard/meeting/rooms`,
      postfix: 'rooms',
    },
  ];
  return [...subMenus];
};

const getInvitationInnerMenus = ({
  permalink,
}: GetMeetingRoomInnerMenusProps) => {
  const subMenus = [
    {
      name: '초대 내역',
      href: `/${permalink}/dashboard/invitation/history`,
      postfix: 'history',
    },
    {
      name: '초대 설정',
      href: `/${permalink}/dashboard/invitation/setting`,
      postfix: 'setting',
    },
  ];
  return [...subMenus];
};
const CompanyInfoWrap = styled.div`
  display: flex;
  padding: 24px 33px;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`;
const CompanyLogoWrap = styled.div<{ isNew?: boolean }>`
  padding: 0 !important;
  position: relative;

  &::before {
    background-color: #ea6a42;
    border: 4px solid #f9f9f9;
    border-radius: 50%;
    content: '';
    display: ${({ isNew }) => (isNew ? 'block' : 'none')};
    height: 16px;
    left: -5px;
    position: absolute;
    top: -5px;
    width: 16px;
    z-index: 1;
  }

  > div {
    border: solid 1px #e7e7e7;
    border-radius: 12px;
    height: 44px;
    overflow: hidden;
    width: 44px;
  }
`;
const CompanyDetailGroup = styled.div`
  color: #646b79;
  flex: 1;
  font-size: 12px;
  line-height: 1.67;
  max-width: 169px;
  padding-left: 16px;
  position: relative;
  white-space: nowrap;

  > strong {
    color: ${colors.NAVY_NEW};
    display: block;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.57;
    overflow: hidden;
    padding-bottom: 4px;
    text-overflow: ellipsis;
  }

  svg {
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translate(0, -50%);
    path[stroke='black'] {
      stroke: #b1b5bc;
    }
  }

  &:hover {
    svg path[stroke='black'] {
      stroke: #646b79;
    }
  }
`;
