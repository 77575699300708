import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import { useRouter } from 'next/router';
import React from 'react';

import Button, { ButtonTheme } from '@/components/button/Button';
import { colors } from '@/styles/constant';
import SVG from '@/svgs';

type ErrorCode = 400 | 401 | 403 | 404 | 500;
const ErrorImage: Readonly<{ [key in ErrorCode]: React.FC }> = {
  400: SVG.HTTP400Image,
  401: SVG.HTTP401Image,
  403: SVG.HTTP403Image,
  404: SVG.HTTP404Image,
  500: SVG.HTTP500Image,
};
const ErrorDefaultTitleStyle: Readonly<{ [key in ErrorCode]: string }> = {
  400: '잘못된 요청입니다',
  401: '로그인이 필요합니다',
  403: '접근이 거부되었습니다',
  404: '찾을 수 없는 페이지입니다',
  500: '일시적인 오류입니다',
};
const ErrorDefaultText: Readonly<{ [key in ErrorCode]: string | JSX.Element }> =
  {
    400: (
      <>
        요청문에 오류가 있거나 서버가 요청을 이해하지 못합니다.
        <br />
        다시 확인하시고 이용해주세요.
      </>
    ),
    401: (
      <>
        이 페이지는 로그인 없이 사용할 수 없습니다.
        <br />
        로그인을 먼저 진행해주세요.
      </>
    ),
    403: '이 페이지에 접근 권한이 없습니다.',
    404: '페이지가 삭제되었거나 잘못된 경로로 들어왔습니다.',
    500: '페이지를 불러오지 못했습니다. 잠시 후에 다시 시도해주세요.',
  };

interface ErrorPageLayoutProps {
  code: ErrorCode;
  title?: string;
  text?: string;
}

const ErrorLayout: React.FC<ErrorPageLayoutProps> = ({ code, title, text }) => {
  const router = useRouter();
  const ImageComponent = ErrorImage[code];

  return (
    <Wrapper>
      <Container>
        <ImageComponent />
        <Title>{title || ErrorDefaultTitleStyle[code]}</Title>
        <Text>{text || ErrorDefaultText[code]}</Text>
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          {code == 500 ? (
            <Button
              theme={ButtonTheme.FILL_BROWN}
              customStyle={css`
                font-size: 16px;
                font-weight: 500;
                padding: 13px 24px;
              `}
              onClick={() => router.reload()}
            >
              다시 시도하기
            </Button>
          ) : (
            <>
              <Button
                theme={ButtonTheme.BORDER_BLACK}
                customStyle={css`
                  font-size: 16px;
                  font-weight: 500;
                  padding: 13px 24px;
                `}
                onClick={() => router.push('/')}
              >
                홈으로 가기
              </Button>
              <Button
                theme={ButtonTheme.FILL_BROWN}
                customStyle={css`
                  font-size: 16px;
                  font-weight: 500;
                  margin-left: 20px;
                  padding: 13px 24px;
                `}
                onClick={() => router.back()}
              >
                이전 페이지
              </Button>
            </>
          )}
        </Box>
        <Contact>
          문제가 계속된다면 고객센터에{' '}
          <a href='mailto:knock@jibmusil.com'>문의</a>
          해주세요.
        </Contact>
      </Container>
    </Wrapper>
  );
};

export default ErrorLayout;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 60px - 133px);
  width: 100%;
`;
const Container = styled.main`
  width: 700px;
`;
const Title = styled.h1`
  color: ${colors.DARK_GRAY};
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 16px;
  margin-top: -36px;
  text-align: center;
`;
const Text = styled.p`
  color: ${colors.DARK_GRAY};
  font-size: 18px;
  opacity: 0.75;
  text-align: center;
`;
const Contact = styled.p`
  color: ${colors.DARK_GRAY};
  font-size: 14px;
  margin-top: 40px;
  opacity: 0.75;
  text-align: center;

  > a {
    color: ${colors.DARK_GRAY};
    font-weight: bold;
    opacity: 1;
    text-decoration: underline;
  }
`;
