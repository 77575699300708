const BackButtonIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22 12.3867H2'
        stroke='#8F7354'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M12 2.38672L2 12.3867L12 22.3867'
        stroke='#8F7354'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
    </svg>
  );
};

export default BackButtonIcon;
