const ProfileEmptyImage = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      fill='none'
      viewBox='0 0 60 60'
    >
      <circle
        cx='30'
        cy='30'
        r='29'
        fill='#C4C4C4'
        stroke='#fff'
        strokeWidth='2'
      />
      <path
        fill='#fff'
        d='M30 30c4.142 0 7.5-3.358 7.5-7.5 0-4.142-3.358-7.5-7.5-7.5-4.142 0-7.5 3.358-7.5 7.5 0 4.142 3.358 7.5 7.5 7.5zM45 40.973c0-1.097-.386-2.171-1.179-2.929C40.231 34.61 35.361 32.5 30 32.5c-5.361 0-10.23 2.11-13.821 5.544-.793.758-1.179 1.832-1.179 2.929V43.5c0 .828.672 1.5 1.5 1.5h27c.828 0 1.5-.672 1.5-1.5v-2.527z'
      />
      <circle cx='30' cy='30' r='28.5' stroke='#fff' strokeWidth='3' />
    </svg>
  );
};

export default ProfileEmptyImage;
