const PeopleIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 12c1.93 0 3.5-1.57 3.5-3.5S10.93 5 9 5 5.5 6.57 5.5 8.5 7.07 12 9 12zm-7 5.25c0-2.33 4.66-3.5 7-3.5s7 1.17 7 3.5V19H2v-1.75zm7-1.5c-1.79 0-3.82.67-4.66 1.25h9.32c-.84-.58-2.87-1.25-4.66-1.25zm1.5-7.25C10.5 7.67 9.83 7 9 7s-1.5.67-1.5 1.5S8.17 10 9 10s1.5-.67 1.5-1.5zm5.54 5.31c1.16.84 1.96 1.96 1.96 3.44V19h4v-1.75c0-2.02-3.5-3.17-5.96-3.44zM18.5 8.5c0 1.93-1.57 3.5-3.5 3.5-.54 0-1.04-.13-1.5-.35.63-.89 1-1.98 1-3.15s-.37-2.26-1-3.15c.46-.22.96-.35 1.5-.35 1.93 0 3.5 1.57 3.5 3.5z'
        fill='black'
      />
    </svg>
  );
};

export default PeopleIcon;
