const ListViewIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='black'
        d='M14.4 8H7.2v1.6h7.2V8zM7.2 11.2h9.6v1.6H7.2v-1.6zM16.8 14.4H7.2V16h9.6v-1.6z'
      />
      <path
        fill='black'
        fillRule='evenodd'
        d='M4 7.2C4 5.433 5.433 4 7.2 4h9.6C18.567 4 20 5.433 20 7.2v9.6c0 1.767-1.433 3.2-3.2 3.2H7.2C5.433 20 4 18.567 4 16.8V7.2zm3.2-1.6h9.6c.884 0 1.6.716 1.6 1.6v9.6c0 .884-.716 1.6-1.6 1.6H7.2c-.884 0-1.6-.716-1.6-1.6V7.2c0-.884.716-1.6 1.6-1.6z'
        clipRule='evenodd'
      />
    </svg>
  );
};

export default ListViewIcon;
