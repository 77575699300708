const DownloadIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        stroke='black'
        strokeMiterlimit='10'
        strokeWidth='2'
        d='M20.538 15.461v2.77c0 1.527-1.37 2.769-3.06 2.769H6.06C4.37 21 3 19.759 3 18.23v-2.769M11.77 3v12.461'
      />
      <path
        stroke='black'
        strokeMiterlimit='10'
        strokeWidth='2'
        d='M7.154 10.846l4.616 4.616 4.615-4.616'
      />
    </svg>
  );
};

export default DownloadIcon;
