import { gql } from '@apollo/client';

import { EnterpriseMemberStatus } from './members';

export interface LoginMutationResult {
  login: {
    user: {
      phoneVerified: boolean;
      emailVerified: Boolean;
      firstName: string;
      lastName: string;
      imageUrl: string;
      specialties: {
        specialty: {
          pk: number;
        };
      }[];
      careers: {
        company: {
          pk: number;
        };
        jobName: string;
        isCurrent: boolean;
      }[];
      enterpriseMemberships: {
        account: { permalink: string };
        status: EnterpriseMemberStatus;
      }[];
    };
    token: string;
    refreshToken: string;
  };
}

export interface LoginMutationVariables {
  email?: string;
  phone?: string;
  password: string;
}

export const loginMutation = gql`
  mutation Login($email: String, $phone: String, $password: String!) {
    login(email: $email, phone: $phone, password: $password) {
      user {
        phoneVerified
        emailVerified
        firstName
        lastName
        imageUrl
        specialties {
          specialty {
            pk
          }
        }
        careers {
          company {
            pk
          }
          jobName
          isCurrent
        }
        enterpriseMemberships {
          account {
            permalink
          }
          status
        }
      }
      token
      refreshToken
    }
  }
`;

export const refreshTokenMutation = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      payload
      refreshToken
      refreshExpiresIn
    }
  }
`;

export const verifyTokenMutation = gql`
  mutation VerifyToken($token: String!) {
    verifyToken(token: $token) {
      payload
    }
  }
`;

export const revokeTokenMutation = gql`
  mutation RevokeToken($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken) {
      revoked
    }
  }
`;

export interface LoginTokenMutationResult {
  getLoginToken: {
    token: string;
  };
}
export const getLoginTokenMutation = gql`
  mutation GetLoginToken {
    getLoginToken {
      token
    }
  }
`;
