const DocumentIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.667 1.667H5c-.917 0-1.667.75-1.667 1.666v13.334c0 .916.742 1.666 1.659 1.666H15c.917 0 1.667-.75 1.667-1.666v-10l-5-5zM13.334 10H6.667v1.667h6.667V10zm0 3.333H6.667V15h6.667v-1.667zM5 16.667h10V7.5h-4.166V3.333H5v13.334z'
        fill='black'
      />
    </svg>
  );
};

export default DocumentIcon;
