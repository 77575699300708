import add from 'date-fns/add';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import isWeekend from 'date-fns/isWeekend';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import ko from 'date-fns/locale/ko';
import parse from 'date-fns/parse';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import Holidays from 'date-holidays';

export const changeDoubleDigits = (value: number | string) => {
  const checkNumber = typeof value === 'string' ? parseInt(value) : value;
  return checkNumber.toLocaleString('ko-KR', { minimumIntegerDigits: 2 });
};

export const changeSecondToUnit = (seconds: number, singleDigits?: boolean) => {
  const getHour = Math.floor(seconds / 3600);
  const getMin = Math.floor((seconds % 3600) / 60);
  const getSec = Math.floor(seconds % 60);

  const digits = singleDigits ? 1 : 2;

  const hourStr = getHour.toLocaleString('ko-KR', {
    minimumIntegerDigits: digits,
  });
  const minStr = getMin.toLocaleString('ko-KR', {
    minimumIntegerDigits: digits,
  });
  const secStr = getSec.toLocaleString('ko-KR', {
    minimumIntegerDigits: digits,
  });

  return {
    hour: getHour,
    min: getMin,
    sec: getSec,
    fullText: `${hourStr}:${minStr}:${secStr}`,
    shortText: `${hourStr}:${minStr}`,
  };
};

export const changeDateToTimeText = (
  date: string | Date,
  cutMin?: boolean,
  singleDigits?: boolean,
) => {
  const timeFormat = singleDigits
    ? cutMin
      ? 'H:m'
      : 'H:m:s'
    : cutMin
    ? 'HH:mm'
    : 'HH:mm:ss';
  const result = format(new Date(date), timeFormat, { locale: ko });
  return result;
};

interface changeDateToTextProps {
  date: string | Date;
  cutMonth?: boolean;
  onlyMonth?: boolean;
}
export const weekdays = ['일', '월', '화', '수', '목', '금', '토'];
export const changeDateToText = ({
  date,
  cutMonth,
  onlyMonth,
}: changeDateToTextProps) => {
  const dateFormat = cutMonth ? 'yyyy-MM' : onlyMonth ? 'MM월' : 'yyyy-MM-dd';
  const dateToString = format(new Date(date), dateFormat, {
    locale: ko,
  });
  const isOffDay =
    cutMonth || onlyMonth
      ? false
      : isWeekend(new Date(date)) || !!checkHoliday(new Date(date));
  const getWeekDay = weekdays[getDay(new Date(date))];

  const text = onlyMonth ? dateToString : `${dateToString}(${getWeekDay})`;

  return { text, isOffDay };
};

export const changeDateToKR = (date: string | Date) => {
  const checkDate =
    date instanceof Date ? format(date, 'yyyy-MM-dd', { locale: ko }) : date;

  return format(new Date(checkDate), 'yyyy년 MM월 dd일', {
    locale: ko,
  });
};

export const changeDateToFormat = (date: Date, formatString: string) => {
  return format(date, formatString, { locale: ko });
};

export const getQueryArg = ({
  date,
  cutMonth,
}: {
  date: string | Date;
  cutMonth?: boolean;
}) => {
  const checkDate =
    date instanceof Date ? format(date, 'yyyy-MM-dd', { locale: ko }) : date;
  const year = parseInt(format(new Date(checkDate), 'yyyy', { locale: ko }));
  const month = parseInt(format(new Date(checkDate), 'M', { locale: ko }));
  const day = parseInt(format(new Date(checkDate), 'd', { locale: ko }));

  if (cutMonth) {
    return { year, month };
  } else {
    return { year, month, day };
  }
};

export const getDate = (year: number, month: number, day?: number) => {
  const date = new Date(year, month - 1, day ?? 1, 0, 0, 0);
  return date;
};

export const getDateParse = (dateString: string, formatString: string) => {
  return parse(dateString, formatString, new Date());
};

export const getPeriod = (isMonth: boolean) => {
  if (isMonth) {
    const getDate = {
      startDate: format(startOfMonth(new Date()), 'yyyy.M.d', { locale: ko }),
      endDate: format(lastDayOfMonth(new Date()), 'yyyy.M.d', { locale: ko }),
    };

    return `${getDate.startDate} - ${getDate.endDate}`;
  } else {
    const getDate = {
      startDate: format(startOfWeek(new Date()), 'yyyy.M.d', { locale: ko }),
      endDate: format(
        add(startOfWeek(new Date()), {
          days: 6,
        }),
        'yyyy.M.d',
        { locale: ko },
      ),
    };

    return `${getDate.startDate} - ${getDate.endDate}`;
  }
};

export const getLastWeek = () => {
  const dayOfLastWeek = new Date();
  dayOfLastWeek.setDate(dayOfLastWeek.getDate() - 7);

  const getDate = {
    startDate: format(
      add(startOfWeek(dayOfLastWeek), { days: 1 }),
      'yyyy.M.d',
      {
        locale: ko,
      },
    ),
    endDate: format(
      add(startOfWeek(dayOfLastWeek), {
        days: 7,
      }),
      'yyyy.M.d',
      { locale: ko },
    ),
  };

  return `${getDate.startDate} - ${getDate.endDate}`;
};

export const checkHoliday = (date: Date | string) => {
  const hd = new Holidays('KR');
  return hd.isHoliday(new Date(date));
};

export const get12HourToString = (hour: number, min: number) => {
  const checkTimeType = hour >= 12 ? '오후' : '오전';
  const checkHourFormat = hour > 12 ? hour - 12 : hour;
  const checkMinFormat = min >= 10 ? min : `0${min}`;

  return `${checkTimeType} ${
    checkHourFormat === 0 ? 12 : checkHourFormat
  }:${checkMinFormat}`;
};

export const getRoomTimeToString = (
  startTime: string,
  endTime: string,
  is24Hour: boolean,
) => {
  if (is24Hour) return '24시간';

  const [startHour, startMin] = startTime
    .split(':')
    ?.map((time: string) => Number(time));
  const [endHour, endMin] = endTime
    .split(':')
    ?.map((time: string) => Number(time));

  return `${get12HourToString(startHour, startMin)} - ${get12HourToString(
    endHour,
    endMin,
  )}`;
};

export const countWeekday = (d1: Date, d2: Date) => {
  const weekdayCount = {
    mon: 0,
    tue: 0,
    wed: 0,
    thu: 0,
    fri: 0,
  };
  const diff = (d2.getTime() - d1.getTime()) / 1000 / 60 / 60 / 24;
  Array(diff + 1)
    .fill(null)
    .map((_, idx) => {
      const tempDate = new Date(d1);
      tempDate.setDate(tempDate.getDate() + idx);
      if (tempDate.getDay() === 1) weekdayCount.mon++;
      else if (tempDate.getDay() === 2) weekdayCount.tue++;
      else if (tempDate.getDay() === 3) weekdayCount.wed++;
      else if (tempDate.getDay() === 4) weekdayCount.thu++;
      else if (tempDate.getDay() === 5) weekdayCount.fri++;
    });
  return weekdayCount;
};
