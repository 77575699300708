import { useRouter } from 'next/router';

import { TOKEN_KEY_NAME } from '../pages/api/set-tokens';

import { client } from '@/client/index';
import { revokeTokenMutation } from '@/client/queries/auth';
import { removeAllToken } from '@/utils/storage';

const useLogOutHook = () => {
  const { push } = useRouter();

  const handleLogOut = async (e?: any) => {
    e?.preventDefault();

    const autoLogin = Boolean(
      window?.localStorage.getItem(TOKEN_KEY_NAME.ACCESS),
    );
    const refreshToken = autoLogin
      ? window.localStorage.getItem(TOKEN_KEY_NAME.REFRESH)
      : window.sessionStorage.getItem(TOKEN_KEY_NAME.REFRESH);

    try {
      await client.mutate({
        mutation: revokeTokenMutation,
        errorPolicy: 'ignore',
        fetchPolicy: 'network-only',
        variables: {
          refreshToken: refreshToken,
        },
      });
    } catch (e) {
      // pass (이미 만료된 refreshToken이어도 무시)
    }

    removeAllToken();

    try {
      await client.resetStore();
    } catch (e) {
      // pass (token이 없어서 query/mutation이 에러가 나도 무시)
    }

    await push('/');
  };

  return { handleLogOut };
};

export default useLogOutHook;
