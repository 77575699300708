const AngryIcon = () => {
  // Do not touch fill/stroke color
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      fill='none'
      viewBox='0 0 40 40'
    >
      <path
        fill='#fff'
        stroke='#EC506A'
        strokeMiterlimit='10'
        strokeWidth='2.5'
        d='M20 38.333c10.125 0 18.333-8.208 18.333-18.333 0-10.125-8.208-18.333-18.334-18.333C9.874 1.667 1.666 9.875 1.666 20c0 10.125 8.208 18.333 18.333 18.333z'
      />
      <path
        fill='#EC506A'
        d='M13.622 17.608c1.32 0 2.391-1.07 2.391-2.39 0-1.321-1.07-2.392-2.391-2.392-1.32 0-2.392 1.07-2.392 2.391 0 1.32 1.071 2.391 2.392 2.391zM26.376 17.608c1.32 0 2.391-1.07 2.391-2.39 0-1.321-1.07-2.392-2.391-2.392s-2.392 1.07-2.392 2.391c0 1.32 1.071 2.391 2.392 2.391z'
      />
      <path
        stroke='#EC506A'
        strokeMiterlimit='10'
        strokeWidth='2.5'
        d='M12.029 29.565l.137-.254c1.225-2.247 4.348-3.731 7.834-3.731 3.485 0 6.608 1.485 7.833 3.731l.138.254'
      />
    </svg>
  );
};

export default AngryIcon;
