const CloseIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        stroke='black'
        strokeMiterlimit='10'
        strokeWidth='2'
        d='M2 2l16 16M2 18L18 2'
      />
    </svg>
  );
};

export default CloseIcon;
