/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { hiddenFooterState } from '@/recoil/atoms';

export const LoadingIconComponent = () => (
  <CircularProgress
    css={css`
      .MuiCircularProgress-circle {
        stroke: #ddd;
      }
    `}
  />
);

export const LoadingBoxComponent = () => (
  <LoadingBoxStyle>{LoadingIconComponent()}</LoadingBoxStyle>
);

const LoadingLayout: React.FC = () => {
  const [_, setHiddenFooterState] = useRecoilState(hiddenFooterState);

  useEffect(() => {
    setHiddenFooterState(true);
    return () => setHiddenFooterState(false);
  }, [setHiddenFooterState]);

  return (
    <Container>
      <CircularProgress
        css={css`
          .MuiCircularProgress-circle {
            stroke: #ddd;
          }
        `}
      />
    </Container>
  );
};

export default LoadingLayout;

const LoadingBoxStyle = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  height: calc(100vh - 60px - 12px - 133px);
  justify-content: center;
  width: 100%;
`;
