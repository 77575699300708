import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';

import { colors } from '@/styles/constant';

export enum ButtonTheme {
  BORDER_BROWN = 'BORDER_BROWN',
  BORDER_GRAY = 'BORDER_GRAY',
  BORDER_BLACK = 'BORDER_BLACK',
  BORDER_BLACK_BOLD = 'BORDER_BLACK_BOLD',
  BORDER_RED = 'BORDER_RED',
  FILL_BROWN = 'FILL_BROWN',
  FILL_GRAY = 'FILL_GRAY',
  FILL_RED = 'FILL_RED',
  EMPTY = 'EMPTY',
}

export interface ButtonProps {
  type?: 'button' | 'reset' | 'submit';
  /** button style theme */
  theme?: ButtonTheme;
  isDisabled?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /** button custom style (ex. size) */
  customStyle?: SerializedStyles;
  isFull?: boolean;
  children: React.ReactNode;
}

interface ButtonStyleProps {
  getThemeStyle: SerializedStyles;
  customStyle?: SerializedStyles;
  fullStyle?: SerializedStyles;
}

const Button: React.FC<ButtonProps> = ({
  type,
  theme = ButtonTheme.BORDER_BROWN,
  isDisabled,
  onClick,
  children,
  customStyle,
  isFull,
}) => {
  return (
    <ButtonStyle
      type={type}
      disabled={isDisabled}
      getThemeStyle={ButtonThemeStyle[theme]}
      customStyle={customStyle}
      fullStyle={isFull ? FullTypeStyle : undefined}
      onClick={onClick}
    >
      {children}
    </ButtonStyle>
  );
};

Button.defaultProps = {
  type: 'button',
};

export default Button;

export const ICON_BROWN = css`
  *[fill='black'] {
    fill: ${colors.BROWN};
  }

  *[stroke='black'] {
    stroke: ${colors.BROWN};
  }
`;
export const ICON_GRAY = css`
  *[fill='black'] {
    fill: #c6cacc;
  }

  *[stroke='black'] {
    stroke: #c6cacc;
  }
`;
export const ICON_DARK_GRAY = css`
  *[fill='black'] {
    fill: ${colors.DARK_GRAY};
  }

  *[stroke='black'] {
    stroke: ${colors.DARK_GRAY};
  }
`;
export const ICON_WHITE = css`
  *[fill='black'] {
    fill: ${colors.WHITE};
  }

  *[stroke='black'] {
    stroke: ${colors.WHITE};
  }
`;

export const ButtonThemeStyle: { [index: string]: SerializedStyles } = {
  [ButtonTheme.BORDER_BROWN]: css`
    background-color: ${colors.WHITE};
    border: solid 1px ${colors.BROWN};
    color: ${colors.BROWN};
    ${ICON_BROWN}

    &:hover {
      border: solid 1px ${colors.DARK_GRAY};
      color: ${colors.DARK_GRAY};
      ${ICON_DARK_GRAY}
    }

    &:disabled {
      background-color: ${colors.WHITE} !important;
      color: ${colors.BROWN};
      opacity: 0.4;
      ${ICON_BROWN}
    }
    &:disabled:hover {
      border: solid 1px ${colors.BROWN};
      color: ${colors.BROWN};
      ${ICON_BROWN}
    }
  `,
  [ButtonTheme.BORDER_GRAY]: css`
    background-color: ${colors.WHITE};
    border: solid 1px #c6cacc;
    color: #c6cacc;
    ${ICON_GRAY}

    &:hover {
      border: solid 1px ${colors.DARK_GRAY};
      color: ${colors.DARK_GRAY};
      ${ICON_DARK_GRAY}
    }
  `,
  [ButtonTheme.BORDER_BLACK]: css`
    background-color: ${colors.WHITE};
    border: solid 1px rgba(57, 59, 65, 30%);
    color: ${colors.DARK_GRAY};
    ${ICON_DARK_GRAY}

    &:hover {
      border: solid 1px ${colors.DARK_GRAY};
    }
  `,
  [ButtonTheme.BORDER_BLACK_BOLD]: css`
    background-color: ${colors.WHITE};
    border: solid 2px rgba(57, 59, 65, 30%);
    border: solid 2px ${colors.DARK_GRAY};
    color: ${colors.DARK_GRAY};
    ${ICON_DARK_GRAY}

    &:disabled {
      background-color: ${colors.WHITE} !important;
      color: ${colors.DARK_GRAY};
      opacity: 0.3;
    }
  `,
  [ButtonTheme.BORDER_RED]: css`
    background-color: ${colors.WHITE};
    border: solid 1px ${colors.RED};
    color: ${colors.RED};

    &:hover {
      border: solid 1px ${colors.RED};
    }
  `,
  [ButtonTheme.FILL_BROWN]: css`
    background-color: ${colors.BROWN};
    color: ${colors.WHITE};
    ${ICON_WHITE}

    &:hover {
      background-color: ${colors.DARK_GRAY};
    }
  `,
  [ButtonTheme.FILL_GRAY]: css`
    background-color: ${colors.DARK_GRAY};
    color: ${colors.WHITE};
    ${ICON_WHITE}
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  `,
  [ButtonTheme.FILL_RED]: css`
    background-color: ${colors.PINK};
    color: ${colors.WHITE};
  `,
  [ButtonTheme.EMPTY]: css`
    &:disabled {
      background: none;
      color: ${colors.DARK_GRAY};
      cursor: not-allowed;
      opacity: 1;
    }
  `,
};

const FullTypeStyle = css`
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  line-height: 56px;
  min-width: 100%;
`;

export const ButtonDefaultStyle = css`
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-weight: 500;
  gap: 7px;
  min-height: 40px;
  padding: 0 16px;

  &:disabled {
    background-color: ${colors.DARK_GRAY};
    color: ${colors.WHITE};
    cursor: not-allowed;
    ${ICON_WHITE}
    opacity: 0.3;
  }
`;

const ButtonStyle = styled.button<ButtonStyleProps>`
  ${ButtonDefaultStyle};
  ${({ getThemeStyle }) => getThemeStyle};
  ${({ customStyle }) => customStyle};
  ${({ fullStyle }) => fullStyle};
`;
