const RadioOnIcon = () => {
  return (
    <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='.5'
        y='.5'
        width='19'
        height='19'
        rx='9.5'
        fill='#fff'
        stroke='#A1856E'
      />
      <rect x='5' y='5' width='10' height='10' rx='5' fill='#A1856E' />
    </svg>
  );
};

export default RadioOnIcon;
