const DropDownNormalIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      fill='none'
      viewBox='0 0 40 40'
    >
      <rect
        width='39'
        height='39'
        x='39.5'
        y='.5'
        fill='#fff'
        stroke='black'
        opacity='.3'
        rx='3.5'
        transform='rotate(90 39.5 0.5)'
      />
      <path
        stroke='black'
        strokeLinejoin='round'
        strokeMiterlimit='10'
        strokeWidth='2'
        d='M14 18l6 6 6-6'
      />
    </svg>
  );
};

export default DropDownNormalIcon;
