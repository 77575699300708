const CalendarIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M19 4H5c-1.105 0-2 .895-2 2v14c0 1.105.895 2 2 2h14c1.105 0 2-.895 2-2V6c0-1.105-.895-2-2-2zM16 2v4M8 2v4M3 10h18'
      />
    </svg>
  );
};

export default CalendarIcon;
