const CalendarViewIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='black'
        d='M11.774 13.08c.119-.198.178-.435.178-.71 0-.413-.172-.738-.516-.975-.339-.237-.804-.355-1.396-.355-.5 0-.906.057-1.217.17-.307.112-.578.25-.815.41l.645 1.041c.06-.037.145-.083.258-.137.113-.06.245-.113.396-.161.155-.049.32-.073.492-.073.193 0 .349.038.467.113.119.07.178.183.178.339 0 .177-.089.322-.266.435-.173.113-.479.17-.92.17h-.363v1.17h.355c.333 0 .586.023.758.072.178.048.299.12.363.217.07.092.105.202.105.331 0 .183-.07.328-.21.436-.134.107-.392.161-.774.161-.215 0-.454-.03-.718-.089-.263-.064-.521-.161-.774-.29v1.29c.247.097.503.175.766.234.264.054.58.081.952.081.559 0 1.013-.078 1.363-.234.355-.156.616-.366.782-.629.172-.264.258-.551.258-.863 0-.409-.126-.723-.379-.944-.247-.225-.602-.365-1.064-.419v-.024c.23-.065.44-.159.629-.282.193-.13.35-.29.467-.484zM14.42 16.88H16v-5.76h-1.403l-1.976 1.614.806.992.654-.54c.059-.049.115-.103.169-.162.059-.06.124-.129.193-.21l-.016.46c-.005.172-.008.328-.008.468v3.137z'
      />
      <path
        fill='black'
        fillRule='evenodd'
        d='M7.2 4C5.433 4 4 5.433 4 7.2v9.6C4 18.567 5.433 20 7.2 20h9.6c1.767 0 3.2-1.433 3.2-3.2V7.2C20 5.433 18.567 4 16.8 4H7.2zM5.6 16.8V9.6h12.8v7.2c0 .884-.716 1.6-1.6 1.6H7.2c-.884 0-1.6-.716-1.6-1.6z'
        clipRule='evenodd'
      />
    </svg>
  );
};

export default CalendarViewIcon;
