const RetryIcon = () => (
  <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='m10 3 2.526 2.526L10 8.053'
      stroke='#9F3C03'
      strokeWidth='1.5'
      strokeMiterlimit='10'
    />
    <path
      d='M12.526 5.527H10c-3.313 0-6 2.713-6 6.013s2.687 5.984 6 5.984 6-2.687 6-5.987'
      stroke='#9F3C03'
      strokeWidth='1.5'
      strokeMiterlimit='10'
    />
  </svg>
);

export default RetryIcon;
