const CompanyEmptyImage = () => {
  return (
    <svg
      width='120'
      height='120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='120' height='120' rx='40' fill='#DDD' />
      <path
        d='M81.6 59.333h-7.2v7.334h7.2v-7.334zM74.4 74h7.2v7.333h-7.2V74z'
        fill='#fff'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 30h36v14.667h36V96H24V30zm7.2 58.667h7.2v-7.334h-7.2v7.334zm14.4 0h7.2v-7.334h-7.2v7.334zm14.4 0h28.8V52H60v7.333h7.2v7.334H60V74h7.2v7.333H60v7.334zm-28.8-44v-7.334h7.2v7.334h-7.2zm0 14.666V52h7.2v7.333h-7.2zM31.2 74v-7.333h7.2V74h-7.2zm14.4-36.667h7.2v7.334h-7.2v-7.334zm7.2 29.334V74h-7.2v-7.333h7.2zM52.8 52v7.333h-7.2V52h7.2z'
        fill='#fff'
      />
      <rect x='.5' y='.5' width='119' height='119' rx='39.5' stroke='#E7E7E7' />
    </svg>
  );
};

export default CompanyEmptyImage;
