const SendIcon = () => {
  // Do not touch fill color
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      fill='none'
      viewBox='0 0 60 60'
    >
      <path fill='#BD987A' d='M2.5 22.5l55-15L15 34.375 2.5 22.5z' />
      <path fill='#CFAB8E' d='M34.375 52.5L57.5 7.5 15 34.375 34.375 52.5z' />
      <path fill='#A1856E' d='M15 52.5V34.375l10 9.375-10 8.75z' />
    </svg>
  );
};

export default SendIcon;
